/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <ConfirmDialog></ConfirmDialog>
    <PSidebar
      class="p-sidebar-lg p-sidebar-close"
      :dismissable="true"
      :visible.sync="displayDialog"
      position="right"
      containerStyle="{width: '70vw'}"
    >
      <offre-detail-viewer :offre="activeOffre" />
    </PSidebar>
    <template></template>
    <div v-if="$can('statistic', 'Offre')" class="statistic-box mb-3">
      <OffreStatistic :extraQueryArg="extraQueryArg" />
    </div>
    <OffreProrogationDialog
      ref="prolongationDialog"
      :title="'Prolongation offre'"
      :item="activeOffre"
      @save="saveProlongationOffre"
    />
    <OffrePublicationDialog
      ref="publicationDialog"
      :title="'Publication offre'"
      :item="activeOffre"
      @save="validerOffre"
    />
    <OffreReconstitutionUpdateDialog
      ref="reconstitutionDialog"
      :title="'Reconstitution offre'"
      :item="activeOffre"
      @save="validerReconstitutionOffre"
    />
    <OffreAnnulationDialog
      ref="annulationDialog"
      :title="'Annulation offre'"
      :item="activeOffre"
      @save="validerAnnulationOffre"
    />
    <div class="container- mt-1">
      <p-progress-bar
        v-if="mxLoading || busy"
        style="height: 0.33em"
        mode="indeterminate"
      />
      <div class="bg-white p-2 rounded-4" v-if="$can('statistic', 'Offre')">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="col field">
              <form class="d-flex">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par'"
                    />
                    <p-multi-select
                      v-model="mxSearchFields"
                      :options="searchFieldsOptions.filter((el) => el.visible)"
                      optionLabel="label"
                      optionValue="value"
                      :editable="true"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-6 col-sm-12 d-flex">
              <div class="input-group me-4">
                <div class="p-inputgroup">
                  <p-dropdown
                    class="flex-grow- me-2"
                    v-model="offreStatus"
                    :options="offreStatusOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Status de l\'offre'"
                    display="chip"
                  />
                  <p-dropdown
                    class="flex-grow-1 me-2"
                    v-model="niveauService"
                    :options="niveauServices"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Niveau service'"
                    display="chip"
                  />
                  <p-dropdown
                    class="flex-grow-1 me-2"
                    v-model="typeOffre"
                    :options="typeOffreOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Type de DE'"
                    display="chip"
                  />
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>
        <transition>
          <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
            <div class="row p-fluid p-3 grid">
              <template v-for="(option, index) in filterOptions">
                <div class="col-12 col-md-3 mb-4" v-if="option.visible" :key="index">
                  <label for="basic">{{ option.label }}</label>
                  <AsyncMultiSelect
                    v-model="option.value"
                    :multiple="true"
                    :queryUrl="option.queryUrl"
                    :optionLabel="option.labelField"
                    :placeholder="option.label"
                    :displayMode="option.displayMode"
                    :emitObject="false"
                    :searchOptions="[option.labelField]"
                  />
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des offres</h4>
        </div>
        <div class="btn-group">
          <a @click.prevent="ajouterOffre" href="" class="style_btn btn btn-warning"
            >Ajouter une offre</a
          >
        </div>

        <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
        <div class="btn-group ms-4">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Affichage mode tableau"
            @click.prevent="viewMode = 'card'"
            class="btn style_btn btn-md"
            :class="{
              'btn-success': viewMode == 'card',
              'btn-light': viewMode == 'table',
            }"
          >
            <i class="bi-grid" />
          </button>
          <button
            @click.prevent="viewMode = 'table'"
            class="btn style_btn"
            :class="{
              'btn-light': viewMode == 'card',
              'btn-success': viewMode == 'table',
            }"
          >
            <i class="bi-table" />
          </button>
        </div>
      </div>
      <div class="d-flex flex-wrap align-items-center">
        <h6 class="text-uppercase" v-if="mxActiveFiltersCount > 0">
          Filtres appliquées:
        </h6>
        <template v-for="(option, index) in filterOptions">
          <!-- <div v-if="option.value != null && option.value.length != 0" :key="index" class="mb-2 border border-primary pt-2"  style="width: 320px"> -->
          <PTag
            severity="warning"
            class="ms-2 mb-2"
            v-for="(item, i) in option.value"
            :key="index + '_' + i"
          >
            <small class="p-1 text-caption">
              {{ item[option.labelField] }}
              <PButton
                v-tooltip.top="`Retirer du filtre`"
                icon="pi pi-times cursor-pointer"
                class="p-button-danger p-button-sm ms-1 p-0"
                @click.prevent.stop="
                  option.value = option.value.filter((el) => el != item)
                "
                style="height: 14px; width: 14px"
              />
            </small>
          </PTag>
          <!-- </div> -->
        </template>
      </div>
      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <PTabView id="offreTabs" :activeIndex.sync="activeTabIndex">
                <PTabPanel header="Mes offres">
                  <!-- <h6>Mes offres</h6> -->
                </PTabPanel>
                <PTabPanel header="Toutes les offres">
                  <!-- <h6>Toutes les offres</h6> -->
                </PTabPanel>
              </PTabView>
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(reference)="data">
                  <div
                    @mouseenter="data.item.displayOffreId = true"
                    @mouseleave="data.item.displayOffreId = false"
                  >
                    <span v-if="data.item.dateReconstitution == null"
                      >#{{ data.item.reference }}</span
                    >
                    <span
                      v-else
                      class="text-uppercase"
                      v-tooltip.top="
                        `Date reconstitution ${
                          data.item.dateReconstitution != null
                            ? $moment(data.item.dateReconstitution).format('DD-MM-YYYY')
                            : ''
                        }`
                      "
                      style="font-size: 1.2rem"
                      v-badge.primary="data.item.dateReconstitution ? 'R' : ''"
                      >#{{ data.item.reference }}</span
                    >
                  </div>
                  <span v-if="data.item.displayOffreId" class="text-muted">{{
                    data.item.id
                  }}</span>
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>
                <template #cell(nom)="{ item: offre }">
                  <span class="text-uppercase">{{ offre.intitule }}</span>
                </template>
                <template #cell(dateEff)="{ item: offre }">
                  <span class="text-uppercase" v-if="offre.dateEff"
                    >{{ offre.dateEff | moment("DD-MM-YYYY") }}
                  </span>
                </template>

                <template #cell(annExp)="{ item: offre }">
                  <span class="text-uppercase" v-if="offre.annExp"
                    >{{ offre.annExp }} {{ offre.uniteExperience }}
                  </span>
                </template>

                <template #cell(dateEch)="{ item: offre }">
                  <span
                    v-tooltip.top="$moment(offre.dateCloture).format('DD-MM-YYYY')"
                    class="text"
                    v-if="offre.dateEch"
                    >{{ $moment(offre.dateCloture).fromNow() }}
                  </span>
                </template>
                <template #cell(status)="{ item: offre }">
                  <PTag
                    class="text-uppercase badge badge-primary"
                    :severity="getStatusOffreColor(offre.status)"
                    v-if="offre.status"
                    >{{ offre.status }}
                  </PTag>
                </template>
                <template #cell(typeOffre)="{ item: offre }">
                  <PTag
                    class="text-uppercase badge badge-primary"
                    :severity="getTypeOffreColor(offre.typeOffre)"
                    v-if="offre.typeOffre"
                    >{{ offre.typeOffre }}
                  </PTag>
                </template>
                <template #cell(niveauService)="{ item: offre }">
                  <PBadge
                    class="text-uppercase badge badge-primary"
                    :severity="getNiveauServiceOffreColor(offre.niveauService)"
                    v-if="offre.niveauService"
                    :value="getNiveauServiceOffreLibelle(offre.niveauService)"
                  />
                </template>

                <template #row-details="row">
                  <CandidatureList
                    :offreId="row.item.id"
                    :extraQueryArg="`offreId=${row.item.id}`"
                  />
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      v-tooltip.left="'Voir les candidatures de l\'offre'"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>
                    <!-- <a
                      class="btn btn-light rounded-0 btn-sm me-1"
                      title="Voir détail offre"
                      href="#"
                      @click.prevent="displayDialog=true"
                      ><i class="bi bi-eye"></i
                    ></a> -->
                    <template
                      v-if="$can('update', 'Offre') && row.item.status != 'ANNULE'"
                    >
                      <a
                        class="btn btn-success rounded-0 btn-sm me-1"
                        @click.stop="update(row.item)"
                        v-tooltip.left="'Modifier l\'offre'"
                      >
                        <i class="bi bi-pencil-square"></i
                      ></a>
                      <a
                        class="btn btn-info rounded-0 btn-sm me-1"
                        v-tooltip.left="'Proroger l\'offre'"
                        v-if="$can('publish', 'Offre')"
                        @click.stop="showProlongerOffre(row.item)"
                        ><i class="bi bi-arrow-up-right-circle"></i
                      ></a>
                      <a
                        class="btn btn-warning rounded-0 btn-sm me-1"
                        v-tooltip.left="
                          (row.item.status == 'DEPUBLIE' ? 'Republier' : 'Publier') +
                          ' l\'offre'
                        "
                        v-if="$can('publish', 'Offre')"
                        @click.stop="publierOffre(row.item)"
                        ><i class="bi bi-box-arrow-up-right"></i
                      ></a>
                      <a
                        class="btn btn-outline-danger rounded-0 btn-sm me-1"
                        v-tooltip.top="'Clôturer l\'offre'"
                        @click.stop="clotureOffre(row.item)"
                        v-if="$can('close', 'Offre')"
                        ><i class="bi bi-slash-circle"></i
                      ></a>
                      <a
                        class="btn btn-danger rounded-0 btn-sm me-1"
                        v-tooltip.left="'Dépublier l\'offre'"
                        @click.stop="depublierOffre(row.item)"
                        v-if="$can('cancel', 'Offre')"
                        ><i class="bi bi-x-octagon"></i
                      ></a>
                      <a
                        class="btn btn-outline-danger rounded-0 btn-sm me-1"
                        v-tooltip.left="'Annuler/Supprimer l\'offre'"
                        v-if="$can('cancel', 'Offre')"
                        @click.stop="annulerOffre(row.item)"
                        ><i class="bi bi-trash"></i
                      ></a>
                      <a
                        class="btn btn-outline-info rounded-0 btn-sm me-1"
                        v-tooltip.left="'Marquer date de reconstitution de l\'offre'"
                        v-if="$can('RECONSTITUTION_UPDATE', 'OFFRE')"
                        @click.stop="showReconstitutionOffre(row.item)"
                        ><i class="bi bi-box-arrow-up-right"></i
                      ></a>
                    </template>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{ mxPagination.page * mxPagination.rowsPerPage }} à
                {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
                {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <!-- <span class="text-muted mb-3">Votre porte-feuille est vide</span> -->
          <!-- <a v-if="$can('create', 'Azoli')" @click.prevent="$router.push({ name: 'espace.entreprise.offre.add' })" href="" class="style_btn btn btn-sm btn-outline-primary">Ajouter un PPI</a> -->
          <a
            @click.prevent="ajouterOffre"
            href=""
            class="style_btn btn btn-sm btn-outline-primary"
            >Ajouter une offre</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import FicheAzoliReport from "../../../components/report/FicheAzoliReport.vue";
import ReportHeader from "../../../components/report/ReportHeader.vue";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import CandidatureList from "./CandidatureList.vue";
import { FILE_UPLOAD_API_URL } from "../../../config/api-config";

import OffreDetailViewer from "../../portail/OffreDetailViewer.vue";
import OffrePublicationDialog from "../../../components/espace/entreprise/offre/editor/dialog/OffrePublicationDialog.vue";
import OffreReconstitutionUpdateDialog from "../../../components/espace/entreprise/offre/editor/dialog/OffreReconstitutionUpdateDialog.vue";
import OffreAnnulationDialog from "../../../components/espace/entreprise/offre/editor/dialog/OffreAnnulationDialog.vue";
import OffreProrogationDialog from "../../../components/espace/entreprise/offre/editor/dialog/OffreProrogationDialog.vue";
import AsyncMultiSelect from "../../../components/common/AsyncMultiSelect.vue";
import OffreStatistic from "../../../components/espace/entreprise/OffreStatistic.vue";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: ``,
    },
  },
  components: {
    FicheAzoliReport,
    ReportHeader,
    OffreDetailViewer,
    CandidatureList,
    AsyncMultiSelect,
    CAutoComplete,
    OffreStatistic,
    OffrePublicationDialog,
    OffreReconstitutionUpdateDialog,
    OffreProrogationDialog,
    OffreAnnulationDialog,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      activeTabIndex: 0,
      search: "",
      dateDeb: null,
      dateFin: null,
      showAdvancedFilter: false,
      displayDialog: false,
      busy: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "table", //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      parAeEnregistres: [],
      activeOffre: {},
      offreStatusOptions: [
        { value: null, label: "Tous" },
        { value: "EN_ATTENTE", label: "EN ATTENTE" },
        { value: "VALIDE", label: "VALIDE" },
        { value: "APPROVE", label: "APPROVE" },
        { value: "NON_APPROUVE", label: "NON_APPROUVE" },
        { value: "PUBLIE", label: "PUBLIE" },
        { value: "DEPUBLIE", label: "DEPUBLIE" },
        { value: "CLOTURE", label: "CLOTURE" },
      ],
      typeOffreOptions: [
        { value: null, label: "Tous" },
        { value: "EXTERNE", label: "EXTERNE" },
        { value: "INTERNE", label: "INTERNE" },
      ],
      niveauServices: [
        { value: null, label: "Tous" },
        { value: "DELEGE", label: "DELEGUE" },
        { value: "SEMI_DELEGE", label: "SEMI DELEGUE" },
      ],
      statutCandidatureOptions: [
        { value: null, label: "Tous" },
        { value: "RECOMMANDE", label: "RECOMMANDE" },
        { value: "CANDIDATE", label: "CANDIDATE" },
        { value: "PROPOSE", label: "PROPOSE" },
        { value: "RETENU", label: "RETENU" },
        { value: "EXIGE", label: "EXIGE" },
      ],
      typeOffre: null,
      offreStatus: null,
      niveauService: null,
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des offres",
                subtitle: "Liste des offres",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "offres/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des offres.pdf",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi  pi-file-excel",
          command: () => {
            this.mxExportToExcel({
              meta: {
                title: "Liste des offres",
                subtitle: "Liste des offres",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "offres/exportToExcel",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des offres.xlsx",
                },
              },
            });
          },
        },
      ],
      searchFields: ["employeur.raisonSocial"],
      searchFieldsOptions: [
        {
          label: "Nom de l'entreprise",
          value: "employeur.raisonSocial",
          visible: true,
        },
        {
          label: "Intitulé",
          value: "intitule",
          visible: true,
        },
        {
          label: "IFU",
          value: "employeur.ifu",
          visible: true,
        },
      ],
      filterOptions: [
        {
          column: "annExp",
          value: "",
          label: "Expériences",
          labelField: "label",
          valueKey: "id",
          queryUrl: "anneeExpriences",
          dropdown: true,
          displayMode: "mega-menu",
          visible: true,
        },
        {
          column: "langue",
          value: "",
          label: "Langues",
          displayMode: "mega-menu",
          dropdown: true,
          labelField: "nom",
          valueKey: "id",
          queryUrl: "ethnies",
          visible: true,
        },
        {
          column: "metier",
          value: "",
          displayMode: "classic",
          label: "Inititulé du poste",
          queryUrl: "romes",
          valueKey: "id",
          labelField: "libelle",
          dropdown: true,
          visible: true,

          // items:,
        },
        {
          column: "niveau",
          value: "",
          label: "Niveaux",
          dropdown: true,
          displayMode: "mega-menu",
          labelField: "code",
          valueKey: "id",
          queryUrl: "niveaux",
          visible: true,
          // items:,
        },
        {
          column: "domaine",
          value: "",
          label: "Domaine",
          displayMode: "mega-menu",
          labelField: "nom",
          valueKey: "id",
          queryUrl: "domaines",
          dropdown: true,
          visible: true,
        },
        {
          column: "nature",
          value: "",
          label: "Nature contrat",
          displayMode: "mega-menu",
          labelField: "code",
          valueKey: "id",
          queryUrl: "natures",
          dropdown: true,
          visible: true,
        },
        {
          column: "employeur.departement.id",
          value: "",
          labelField: "nom",
          displayMode: "classic",
          valueKey: "id",
          label: "Département",
          queryUrl: "departements",
          dropdown: true,
          visible: true,
        },
      ],
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeDemandeur = {
          ...val,
          ...val.azoli,
        };
      }
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
    activeTabIndex() {
      this.queryStatistics();
    },
    // dateDeb(){
    //   this.queryStatistics()
    // },
    // dateFin(){
    //   this.queryStatistics()
    // }
  },
  computed: {
    ...mapGetters({
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      candidatures: "candidature/candidatures",
    }),
    canModify() {
      return this.activeTabIndex == 0;
    },
    checkCanModify() {
      if (!this.canModify) {
        this.$toast.error("Vous n'ếtes pas autorisé à intervernir sur cette offre.", {
          position: "bottom-right",
          duration: 8000,
        });
        return false;
      }
      return true;
    },
    tableFields() {
      return [
        "#",
        // 'index',
        { key: "reference", label: "Ref" },
        { key: "intitule", label: "Intitulé" },
        { key: "employeur.raisonSocial", label: "Entreprise" },
        { key: "niveauService", label: "Niveau Service" },
        // { key: "lieuDeDepot", label: "Lieu" },
        { key: "ageMin", label: "Age Min" },
        { key: "ageMax", label: "Age Max" },
        { key: "nombre", label: "Nbre. Post" },
        { key: "candidaturesCount", label: "Nbre Cand." },
        { key: "typeOffre", label: "Type Offre" },
        { key: "annExp", label: "Durrée expérience" },
        { key: "dateEff", label: "Date d'éffet" },
        { key: "dateEch", label: "Date d'échéance" },
        { key: "status", label: "statut" },
        { key: "creePar", label: "Créé par" },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    fileApiUrl() {
      return FILE_UPLOAD_API_URL;
    },
  },
  methods: {
    ...mapActions({
      fetchAzoliStatistics: "statistic/fetchAzoliStatistics",
      validationOffre: "offre/validationOffre",
      prolongerOffre: "offre/prolongerOffre",
      cloturerOffre: "offre/cloturerOffre",
      checkIfCanPostOffre: "offre/checkIfCanPostOffre",
      updateReconstitutionOffre: "offre/updateReconstitutionOffre",
      updateAnnulationOffre: "offre/updateAnnulationOffre",
    }),
    validation() {},
    getTypeOffreColor(type) {
      let color = "EXTERNE";
      if (type == "INTERNE") {
        color = "danger";
      }
      return color;
    },
    getStatusOffreColor(status) {
      let color = "EN_ATTENTE";
      if (status == "VALIDE") {
        color = "warning";
      } else if (status == "PUBLIE") {
        color = "success";
      } else if (status == "APPROUVE") {
        color = "info";
      } else if (status == "DEPUBLIE" || status == "ANNULE") {
        color = "danger";
      } else if (status == "CLOTURE") {
        color = "danger";
      } else if (status == "EN_ATTENTE") {
        color = "secondary";
      }
      return color;
    },
    getNiveauServiceOffreColor(service) {
      let color = "";

      if (service == "SERVICE_DELEGUE") {
        color = "success";
      } else if (service == "SERVICE_NON_DELEGUE") {
        color = "warning";
      } else if (service == "SERVICE_SEMI_DELEGUE") {
        color = "danger";
      }
      return color;
    },
    getNiveauServiceOffreLibelle(service) {
      let label = "DELEGUE";

      if (service == "SERVICE_DELEGUE") {
        label = "DELEGUE";
      } else if (service == "SERVICE_NON_DELEGUE") {
        label = "NON DELEGUE";
      } else if (service == "SERVICE_SEMI_DELEGUE") {
        label = "SEMI DELEGUE";
      }
      return label;
    },
    setDatePub(offre) {
      this.activeOffre = { ...offre };
    },
    ajouterOffre() {
      this.checkIfCanPostOffre().then((data) => {
        if (data.canPost) {
          this.$router.push({ name: "espace.entreprise.offre.add" });
        } else {
          this.$toast.info(
            "Vous ne pouvez pas poster une offre pour le moment. " +
              "Veuillez vous assurer d'avoir signé une convention de partenariat avec l'ANPE." +
              "Contactez votre conseiller ANPE pour plus d'information.",
            {
              position: "bottom-right",
              duration: 8000,
            }
          );
        }
      });
    },
    depublierOffre(offre) {
      if (!this.checkCanModify) return;
      if (offre.status != "PUBLIE") {
        this.$toast.error("Cette offre n'est pas publiée.", {
          position: "bottom-right",
          duration: 8000,
        });
        return;
      }
      this.$swal
        .fire({
          title: "Confirmation",
          text: "Confirmez-vous l'annulation de cette offre ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Non",
          confirmButtonText: "Oui",
        })
        .then((result) => {
          if (result.isConfirmed) {
            offre.status = "DEPUBLIE";
            this.validationOffre(offre).then(() => {
              this.$toast.error("Offre annulée ", {
                position: "bottom-right",
                duration: 8000,
              });
            });
          }
        });
    },
    annulerOffre(offre) {
      if (!this.checkCanModify) return;
      this.activeOffre = { ...offre };
      this.$refs.annulationDialog.show();
    },
    validerReconstitutionOffre(offre) {
      if (!this.checkCanModify) return;
      this.$swal
        .fire({
          title: "Confirmation",
          text: "Confirmez-vous la date reconstitution de cette l'offre ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Non",
          confirmButtonText: "Oui",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.updateReconstitutionOffre({
              ...offre,
            }).then(() => {
              this.$toast.success("Opération réussie", {
                position: "bottom-right",
                duration: 8000,
              });
            });
          }
        });
    },
    validerAnnulationOffre(offre) {
      if (!this.checkCanModify) return;
      this.$swal
        .fire({
          title: "Confirmation",
          text: "Etes-vous sur de vouloir annuler de cette l'offre?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Non",
          confirmButtonText: "Oui",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.updateAnnulationOffre({
              ...offre,
            }).then(() => {
              this.$toast.success("Opération réussie", {
                position: "bottom-right",
                duration: 8000,
              });
            });
          }
        });
    },
    validerOffre(offre) {
      if (!this.checkCanModify) return;
      this.$swal
        .fire({
          title: "Confirmation",
          text: "Confirmez-vous la publication de cette l'offre ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Non",
          confirmButtonText: "Oui",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.busy = true;
            this.validationOffre({
              ...offre,
              status: "PUBLIE",
            })
              .then(() => {
                this.$toast.success("Offre publiée", {
                  position: "bottom-right",
                  duration: 8000,
                });
                this.busy = false;
              })
              .catch((err) => (this.busy = false));
          }
        });
    },
    clotureOffre(offre) {
      if (!this.checkCanModify) return;
      if (offre.status != "PUBLIE") {
        this.$toast.error("Impossible de clôturer. Cette offre n'est pas publiée.", {
          position: "bottom-right",
          duration: 8000,
        });
        return;
      }
      this.$swal
        .fire({
          title: "Confirmation",
          text: `Voulez-vous vraiment clôturer l'offre #${offre.reference} intitulé ${offre.intitule}?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Non",
          confirmButtonText: "Oui",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.cloturerOffre(offre).then(() => {
              this.$toast.success("Offre clôturée", {
                position: "bottom-right",
                duration: 8000,
              });
            });
          }
        });
    },
    saveProlongationOffre(offre) {
      if (!this.checkCanModify) return;
      if (offre.status != "PUBLIE") {
        this.$toast.error("Impossible. Cette offre n'est pas publiée.", {
          position: "bottom-right",
          duration: 8000,
        });
        return;
      }
      this.$swal
        .fire({
          title: "Confirmation",
          text: `Voules-vous vraiment prolonger l'offre ,#${offre.reference} - ${offre.intitule}, de ${offre.dureeProlongation} jours?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Non",
          confirmButtonText: "Oui",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.prolongerOffre(offre).then(() => {
              this.$toast.success("Offre prolongée", {
                position: "bottom-right",
                duration: 8000,
              });
            });
          }
        });
    },
    showProlongerOffre(offre) {
      if (!this.checkCanModify) return;
      this.activeOffre = { ...offre };
      if (offre.status != "PUBLIE") {
        this.$toast.error(
          "Cette offre ne peut pas être prolongée parce qu'elle n'est pas publiée.",
          {
            position: "bottom-right",
            duration: 8000,
          }
        );
        return;
      }
      this.$refs.prolongationDialog.show();
    },
    showReconstitutionOffre(offre) {
      if (!this.checkCanModify) return;
      this.activeOffre = { ...offre };
      this.$refs.reconstitutionDialog.show();
    },
    publierOffre(offre) {
      if (!this.checkCanModify) return;
      this.activeOffre = { ...offre };

      if (offre.typeOffre != "EXTERNE") {
        this.$toast.error("Seul les offres EXTERNEs sont publiables.", {
          position: "bottom-right",
          duration: 8000,
        });
        return;
      }

      if (offre.status == "PUBLIE") {
        this.$toast.info("Cette offre est déjà publiée.", {
          position: "bottom-right",
          duration: 8000,
        });
        return;
      }

      if (offre.status != "APPROUVE" && offre.status != "DEPUBLIE") {
        this.$toast.error(
          "Vous n'avez pas encore approuvée cette offre pour la publication.",
          {
            position: "bottom-right",
            duration: 8000,
          }
        );
        return;
      }
      this.$refs.publicationDialog.show();
    },
    // transfertDemandeur(agent) {
    //   console.log("Agent: ", agent);
    //   if (this.selected.length == 0) {
    //     this.$toast.error(
    //       "Aucun demandeur sélectionné. Passez en mode tableau puis sélectionnez le(s) demandeur(s) avant de continuer. ",
    //       {
    //         position: "top-right",
    //         duration: 8000,
    //       }
    //     );
    //     return;
    //   }
    //   this.transfererVersConseiller({
    //     agent,
    //     demandeur: this.selected[0],
    //   }).then((data) => {
    //     console.log(data);
    //     this.$toast.success("Opération réussie!", {
    //       position: "top-right",
    //       duration: 8000,
    //     });
    //   });
    // },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "offre",
        search: this.search,
        fetcherMethod: "offre/fetchOffres",
        dataGetter: "offre/offres",
        paginationGetter: "offre/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 8,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          let paramPlus = this.mxAddQueryParam("regroupements", this.demandeurType);
          paramPlus = this.mxAddQueryParam("status", this.offreStatus, paramPlus);
          paramPlus = this.mxAddQueryParam("typeOffre", this.typeOffre, paramPlus);
          paramPlus = this.mxAddQueryParam(
            "forCurrentUserOnly",
            this.canModify,
            paramPlus
          );

          let params = `${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        // searchFields: this.searchFields,
        searchFields: this.searchFields,
      });
    },
    // sformatToDataTable(data, { keyName }) {
    //   let summary = {};
    //   return Object.keys(data).map((k) => {
    //     let { key, M = 0, F = 0, total = 0 } = data[k];
    //     summary.M += M;
    //     summary.F += F;
    //     summary.total += total;
    //     if (data[k]["TOTAL"] == undefined) {
    //       return {
    //         [keyName]: key,
    //         M,
    //         F,
    //         total,
    //       };
    //     } else {
    //       return {
    //         [keyName]: "TOTAL",
    //         ...summary,
    //       };
    //     }
    //   });
    // },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    uploadFile(de) {
      console.log(de);
      this.$refs[`pond2`].click();
    },
    onProgress(e) {
      console.log(e);
    },
    // downloadFiche(de) {
    //   // FileService.downloadFile(`demandeurs${de.id}/pdf`, `fichier_${de.id}.pdf`).then(()=>{})
    //   // this.downloadAzoli(de).then(()=>{})
    //   this.fetchDossier({
    //     demandeurId: de.id,
    //   }).then(() => {
    //     console.log(this.$refs.html2Pdf);
    //     this.$refs.html2Pdf.generatePdf();
    //   });
    // },
    // editDemandeur(demandeur) {
    //   console.log(demandeur);
    //   this.activeDemandeur = { ...demandeur };
    //   this.$router.push({ name: "espace.ce.de.edit" });
    // },
    // showDemandeur(demandeur) {
    //   console.log(demandeur);
    //   this.activeDemandeur = { ...demandeur };
    //   this.$router.push({
    //     name: "espace.ce.de.detail",
    //     params: { demandeurId: demandeur.id },
    //   });
    // },
    update(offre) {
      if (!this.checkCanModify) return;
      console.log("avant offre");
      console.log(offre);
      //this.activeDemandeur = { ...demandeur }
      this.$router.push({
        name: "espace.entreprise.offre.edit",
        params: { offreId: offre.id },
      });
    },
  },
};
</script>

<style>
#offreTabs .p-tabview-panels {
  display: none;
}
</style>
