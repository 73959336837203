<template>
  <div>
    <div>
      <!-- <PTabMenu :model="statisticTypes" :activeIndex.sync="activeStatisticTypeIndex" /> -->
    </div>
    <div class="d-flex justify-content-between">
      <h3 class="my-2">Requêteur de {{ activeStatisticType.label }}</h3>
      <div class="">
        <multiselect
          v-model="activeStatisticType"
          :options="statisticTypes"
          :multiple="false"
          style="min-width: 120px"
          :close-on-select="true"
          placeholder="Type de statistiques"
          label="label"
          track-by="value"
          :preselect-first="true"
        />
      </div>
    </div>
    <h5>
      Liste des {{ activeStatisticType.cible }} {{ lineName }} et par {{ columnName }}
    </h5>
    <div class="card">
      <!-- <h5>
      </h5> -->
      <PDivider />

      <div class="row justify-content-b mb-3 px-2">
        <!-- <div class="col-3">
          <multiselect
            v-model="lines"
            :options="indicateurs"
            :multiple="true"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            :max="10"
            placeholder="Lignes"
            label="label"
            track-by="code"
            :preselect-first="false"
          />
        </div> -->
        <div class="col-3">
          <multiselect
            v-model="columns"
            :options="indicateursColumns"
            :multiple="true"
            :max="1"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Colonnes"
            :readonly="
              activeStatisticType != null && activeStatisticType.value === 'STATISTIC_DE'
            "
            label="label"
            track-by="code"
            :preselect-first="false"
          />
        </div>
        <div class="col-3">
          <multiselect
            v-model="pivots"
            :options="indicateursPivots"
            :multiple="true"
            :max="1"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Sous groupe"
            label="label"
            track-by="code"
            :preselect-first="false"
          />
        </div>
        <div class="field col-12 col-md-2">
          <!-- <label for="basic">Date de début</label> -->
          <p-calendar
            placeholder="Date de début"
            v-model="dateDeb"
            locale="fr"
            :showIcon="true"
            :manualInput="false"
          />
        </div>
        <div class="field col-12 col-md-2">
          <!-- <label for="basic">Date de fin</label> -->
          <p-calendar
            placeholder="Date de fin"
            class="flex-grow-1"
            v-model="dateFin"
            :showIcon="true"
            :manualInput="false"
          />
        </div>
        <div class="col-1">
          <div style="background: yellow;" class="d-flex justify-content-between">
            <!-- <div class="field-checkbox d-flex align-items-center">
              <PInputSwitch v-model="transposed" class="mr-3" />
              <label for="transposed" class="pl-2"> Transposé</label>
            </div> -->
            <a
                href="#"
                @click.prevent="submitQuery"
                class="style_btn btn btn-primary"
                id="button-query"
                ><i class="bi bi-search"></i
                ></a>
            <button
              type="button"
              @click.prevent="showAdvancedFilter = !showAdvancedFilter"
              class="ms-2 style_btn btn btn-warning"
              id="button-query"
            >
              <i class="bi bi-filter"></i>
            </button>
          </div>
        </div>
      </div>
        <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
          <div class="row p-fluid p-3 grid">
            <div class="field col-12 col-md-4">
                <AsyncMultiSelect
                    v-model="filter.agents"
                    :multiple="true"
                    :queryUrl="'agents'"
                    :optionLabel="'nomPrenom'"
                    :optionValue="'id'"
                    :placeholder="'Rechercher un agent'"
                    :emitObject="false"
                    :displayMode="'classic'"
                    :searchOptions="['nomPrenom']"
                    />
            </div>
            <div class="field col-12 col-md-3">
                <AsyncMultiSelect
                    v-model="filter.antennes"
                    :multiple="true"
                    :queryUrl="'antennes'"
                    :optionLabel="'intitule'"
                    :optionValue="'id'"
                    :placeholder="'Rechercher une antenne'"
                    :emitObject="false"
                    :searchOptions="['intitule']"
                    :displayMode="'classic'"
                    />
            </div>
            <div class="field col-12 col-md-3">
              <!-- <label for="basic">Périodicité</label> -->
              <!-- <multiselect
                v-model="periodicite"
                :options="indicateursColumns"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                :max="10"
                placeholder="Périodicité"
                label="label"
                track-by="code"
                :preselect-first="false"
              /> -->
            </div>
          </div>
        </div>
      </transition>
      <div class="d-flex justify-content-between mb-3 px-2 border">
        <div>
          <PButton
            v-for="(mode, i) in viewModeOptions"
            :key="i"
            @click.prevent="() => (viewMode = mode.value)"
            :icon="mode.icon"
            v-tooltip="mode.label"
            class="p-button-rounded p-button-success p-button-lg mx-2"
            :class="{ 'p-button-outlined': mode.value != viewMode }"
          />
        </div>
        <button type="buton" class="btn btn-primary" @click="exportCSV">Export</button>
      </div>
      <PDivider />
      <p-progress-bar v-if="loading" style="height: 0.33em" mode="indeterminate" />

      <template>
        <div
          data-scrollselectors=".p-datatable-wrapper"
          class="p-datatable-sm p-datatable p-component p-datatable-responsive-scroll p-datatable-grouped-header p-datatable-gridlines"
          pv_id_14=""
        >
          <div class="p-datatable-wrapper">
            <table id="statisticTable" role="table" class="p-datatable-table">
              <template>
                <thead role="rowgroup" class="p-datatable-thead">
                  <template>
                    <tr role="row">
                      <th role="cell" :rowspan="dataColumnsPivots.pivots.length != 0? 2 : 0" class="" style="width: 3em">
                        <span class="p-column-title">N°</span>
                      </th>
                      <th :rowspan="dataColumnsPivots.pivots.length != 0? 2 : 0" role="cell">Activites</th>
                      <th :rowspan="dataColumnsPivots.pivots.length != 0? 2 : 0" role="cell">Sous groupe</th>
                      <!-- <th rowspan="2" role="cell">Nombres</th> -->
                      <template v-if="dataColumnsPivots.pivots.length != 0">
                        <th
                          role="cell"
                          class="text-cente px-2"
                          v-for="(reg, idx) in dataColumnsPivots.pivots"
                          :colspan="dataColumnsPivots.columns.length"
                          :key="idx"
                        >
                          {{ reg }}
                        </th>
                      </template>

                      <th
                        v-else
                        role="cell"
                        class="text-cente px-2"
                        v-for="(reg, idx) in dataColumnsPivots.columns"
                        :key="idx"
                      >
                        {{ reg }}
                      </th>
                    </tr>
                  </template>

                  <tr v-if="hasPivot" role="row" class="">
                    <template v-for="(piv, pidx) in dataColumnsPivots.pivots">
                      <th
                        v-for="(col, idx) in dataColumnsPivots.columns"
                        role="cell"
                        :key="`_cells_${idx}_${pidx}`"
                        class=""
                      >
                        <template v-if="hasPivot">
                          <span :key="`_cells_${idx}`">{{ col }}</span>
                        </template>
                      </th>
                    </template>
                  </tr>
                </thead>
              </template>
              <tbody role="rowgroup" class="p-datatable-tbody">
                <tr v-for="(stat, i) in stats" :key="'_rows_' + i" role="row" class="">
                  <td>
                    {{ i + 1 }}
                  </td>
                  <td
                    v-if="i == 0 || stats[i - 1]._group != stats[i]._group"
                    :rowspan="stat._groupLength"
                    :colspan="stat._groupLength == 1 ? 2 : 1"
                  >
                    {{ stat.label }}
                  </td>
                  <td
                    v-if="stat._groupLength > 1"
                    :class="{ 'fw-bold': stat.subLabel == 'Sous-Total' }"
                  >
                    {{ stat.subLabel }}
                  </td>

                  <template v-if="dataColumnsPivots.pivots.length != 0">
                    <template v-for="(piv, pidx) in dataColumnsPivots.pivots">
                      <td
                        v-for="(col, idx) in dataColumnsPivots.columns"
                        role="cell"
                        :key="`_cells_${idx}_${pidx}`"
                        :class="{ 'fw-bold': stat.subLabel == 'Sous-Total' }"
                      >
                        <span>{{ stat[piv + "_._" + col] }}</span>
                      </td>
                    </template>
                  </template>
                  <template v-else>
                    <td
                      v-for="(col, idx) in dataColumnsPivots.columns"
                      role="cell"
                      :key="`_cells_${idx}`"
                      class=""
                    >
                      <span>{{ stat[col + "_._" + col] }} </span>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <!-- <PChart v-else :type="activeChartType" :data="chartData" :options="chartOptions" /> -->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import { download_table_as_csv } from "../../../../utils/download-util.js";
import AsyncMultiSelect from "@/components/common/AsyncMultiSelect.vue";

import {
  aggregationOptions,
  priseEnChargeAggregationOptions,
  autresAggregationOptions,
  deIndicateurOpttions,
  offreIndicateurOpttions,
  priseEnChargeIndicateurOpttions,
} from "./requeteur-options2.js";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
    typeRequeteur: {
      type: String,
      default: "NATIONAL", //NATIONAL, SECTORIEL
    },
  },
  components: {
    Multiselect,
    AsyncMultiSelect
  },
  data() {
    return {
      stats: {},
      lines: [],
      viewMode: "DATA",
      transposed: false,
      periodicite: null,
      loading: false,
      showAdvancedFilter: false,
      columns: [],
      groupedData: false,
      pivots: [],
      activeStatisticTypeIndex: 0,
      activeStatisticType: null,
      dateDeb: null,
      dateFin: null,

      filter: {
        antennes: [],
        agents: []
      },

      statisticTypes: [
        {
          label: "Statistiques DE",
          icon: "pi pi-id-card",
          code: "STATISTIC_DE",
          value: "STATISTIC_DE",
          cible: "demandeurs d'emploi",
        },
        {
          label: "Statistiques PEC",
          icon: "pi pi-link",
          value: "STATISTIC_PPRISE_EN_CHARGE",
          cible: "prises en charge",
        },
        {
          label: "Statistiques OFFRE",
          icon: "pi pi-building",
          value: "STATISTIC_OFFRE",
          cible: "offres",
        },
      ],
      viewModeOptions: [
        { label: "Afficher en mode tableau", icon: "pi pi-table", value: "DATA" },
        // {
        //   label: "Afficher en mode histogramme",
        //   icon: "pi pi-chart-bar",
        //   value: "GRAPHIC_BAR_CHART",
        // },
        // {
        //   label: "Afficher en mode diagramme circulaire",
        //   icon: "pi pi-chart-pie",
        //   value: "GRAPHIC_DOUGNHUT_CHART",
        // },
        // {
        //   label: "Afficher en mode courbe",
        //   icon: "pi pi-chart-line",
        //   value: "GRAPHIC_LINE_CHART",
        // },
      ],
    };
  },
  created() {
    this.activeStatisticType = this.statisticTypes[0];
  },
  mounted() {
    this.columns = [this.indicateursColumns[0]];

    this.queryStatistics();
  },
  computed: {
    dataColumnsPivots() {
      let data = this.stats[0] || {};
      let cols = [];
      let pivots = [];
      Object.keys(data || {}).forEach((key) => {
        const colPivots = key.split("_._");
        if (colPivots.length > 1) {
          pivots.push(colPivots[0]);
          cols.push(colPivots[1]);
        }
      });
      return {
        columns: [...new Set(cols)].sort(),
        pivots: this.hasPivot ? [...new Set(pivots)].sort() : [],
      };
    },
    indicateursPivots() {
      let options = this.activeStatisticType.value !== "STATISTIC_DE"
        ? autresAggregationOptions
        : aggregationOptions;

        if(this.activeStatisticType.value == "STATISTIC_PPRISE_EN_CHARGE"){
            options = priseEnChargeAggregationOptions
        }

      console.log(options)
      if(this.typeRequeteur != "NATIONAL"){
        options = options.filter(o => o.isNationalOnly != true)
      }

      return options
    },
    indicateursColumns() {
      const indicateur = this.activeStatisticType.value;
      let options = deIndicateurOpttions;
      if (indicateur == "STATISTIC_DE") {
        options = deIndicateurOpttions;
      } else if (indicateur == "STATISTIC_OFFRE") {
        options = offreIndicateurOpttions;
      } else if (indicateur == "STATISTIC_PPRISE_EN_CHARGE") {
        options = priseEnChargeIndicateurOpttions;
      }
      return options;
    },
    activeChartType() {
      let chartType = "bar";

      if (this.viewMode == "GRAPHIC_BAR_CHART") chartType = "bar";

      if (this.viewMode == "GRAPHIC_LINE_CHART") chartType = "line";

      if (this.viewMode == "GRAPHIC_DOUGNHUT_CHART") chartType = "doughnut";
      return chartType;
    },

    hasPivot() {
      return (
        this.pivots.length > 0 &&
        this.columns.length > 0 &&
        this.pivots[0] != this.columns[0]
      );
    },
    regroupements() {
      return ["Masculin", "Feminin"];
    },

    columnName() {
      return this.lines.length > 0 ? this.lines[0].label : "";
    },
    lineName() {
      return this.columns.length > 0 ? this.columns[0].label : "";
    },
  },
  watch: {
    activeStatisticType: {
      handler(val) {
        console.log(val);
        // this.activeStatisticTypeIndex = this.statisticTypes.indexOf(
        //   (el) => el.value == val.value
        // );
        this.columns = [this.indicateursColumns[0]];

        this.queryStatistics();
      },
      deep: true,
    },
    lines() {
      this.queryStatistics();
    },
    columns() {
      this.queryStatistics();
    },
    pivots() {
      this.queryStatistics();
    },
    transposed() {
      const oldLines = [...this.lines];
      this.lines = [...this.columns];
      this.columns = [...oldLines];
    },
    dateDeb() {
      this.queryStatistics();
    },
    dateFin() {
      this.queryStatistics();
    },
},
methods: {
    ...mapActions({
        fetchGlobalStatistics: "statistic/fetchGlobalStatistics",
    }),
    submitQuery(){
        this.queryStatistics();
    },
    getDataColumnsPivots(data) {
      let cols = [];
      let pivots = [];
      Object.values(data || {}).forEach((el) => {
        cols.push(el.column);
        pivots.push(el.pivot);
      });
      return {
        columns: [...new Set(cols)],
        pivots: this.hasPivot ? [...new Set(pivots)] : [],
      };
    },
    queryStatistics() {
      const dateDeb =
        this.dateDeb != null
          ? (this.dateDeb || new Date("2021-12-31")).toISOString().substring(0, 10)
          : null;
      const dateFin =
        this.dateFin != null
          ? (this.dateFin || new Date("2080-12-31")).toISOString().substring(0, 10)
          : null;
      this.loading = true;

      let urlParams = this.extraQueryArg
      if(this.filter.antennes.length > 0){
        urlParams += `&antenneIdsIn=${this.filter.antennes.map(el => el.id).join(',')}`
      }
      if(this.filter.agents.length > 0){
        urlParams += `&agentIdsIn=${this.filter.agents.map(el => el.id).join(',')}`
      }
      if(this.typeRequeteur){
        urlParams += `&dataAccessLevel=${this.typeRequeteur}`
      }
      this.fetchGlobalStatistics({
        lines: [],
        columns: this.columns.map((el) => el.code),
        pivots: this.pivots.map((el) => el.code),
        statisticType: this.activeStatisticType.value,
        extraQueryArg: urlParams,
        dateDeb,
        dateFin,
      })
        .then((data) => {
          this.loading = false;
          this.stats = [...data];
        })
        .catch((err) => (this.loading = false));
    },
    exportCSV() {
      download_table_as_csv("statisticTable", ",", () => {
        this.$toast.success("Téléchargement terminé!", {
          position: "top-right",
          duration: 8000,
        });
      });
    },
  },
};
</script>
