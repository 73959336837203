import { required, email, numeric } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import { post } from "@/api/api";
import moment from "moment/moment";
import md5 from 'md5'

let npiValideInfo = null;
let hasOngoingNpiRequest = false

export function initializeFormValidation() {
    extend("email", {
        ...email,
        message: "Adresse email invalide",
    });

    extend("numeric", {
        ...numeric,
        message: "Ce champ doit contenir rien que des chiffres",
    });

    extend("required", {
        ...required,
        message: "Ce champ est obligatoire",
    });

    extend("min", {
        validate(value, { length }) {
            return value.length >= length;
        },
        params: ["length"],
        message: "Ce champ doit avoir au moins {length} caractères",
    });

    extend("max", {
        validate(value, { length }) {
            return length > value.length;
        },
        params: ["length"],
        message: "Ce champ doit avoir au plus {length} caractères",
    });

    extend("equal", {
        validate(value, { length }) {
            return parseInt(length) === parseInt(value.length);
        },
        params: ["length"],
        message: "Ce champ doit avoir exactement {length} caractères",
    });

    extend("exact", {
        validate(value, { length }) {
            return parseInt(length) === parseInt(value.length);
        },
        params: ["length"],
        message: "Ce champ doit avoir exactement {length} caractères",
    });

    extend("minAge", {
        validate(value, { length }) {
            const age = moment().diff(moment(value, "DD/MMM/YYYY"), "years");
            return age >= length;
        },
        params: ["length"],
        message:
            "Seulement les personnes agées d'au moins {length} ans sont acceptées.",
    });

    extend("npiValidite", async function (value, params) {
        console.log(params)
        let type = params[0];
        let npi = params[1];
        let nom = params[2];
        let prenom = params[3];

        if(hasOngoingNpiRequest) return true; //Avoid multiple request to the server
        
        if(nom == 'null' || nom == null || prenom == 'null' || prenom == null) return true //Unitile de vérifier
        console.log(npi, nom, prenom, typeof(prenom))
        
        if(nom.length < 2 || prenom.length < 2) return true
        
        try{
            if((npiValideInfo == null || npi != npiValideInfo.checker.npi)){
                hasOngoingNpiRequest = true
                const { data } = await post(
                    "/oauth/verifier/disponibiliteNpi/",
                    {
                        type,
                        npi: npi,
                        nom,
                        prenom
                    }
                )
    
                hasOngoingNpiRequest = false
                npiValideInfo = data
            }
            const computedMd5 = md5(`${nom} ${prenom}`.toUpperCase())
            return computedMd5 !== npiValideInfo.nomPrenomHash? 'Le nom et/ou le prénom saisi ne correspond pas aux informations du NPI.' : true
        } catch(err){
            console.log(err)
            hasOngoingNpiRequest = false
            return 'Erreur de vérification'
        }
    });


    extend("npiValiditeDE", async function (value, params) {
        let type = params[0];
        let npi = params[1];
        let nom = params[2];
        let prenom = params[3];

        if(hasOngoingNpiRequest) return true; //Avoid multiple request to the server
        
        if(nom == 'null' || nom == null || prenom == 'null' || prenom == null) return true //Unitile de vérifier
        console.log(npi, nom, prenom, typeof(prenom))
        
        if(nom.length < 2 || prenom.length < 2) return true
        
        try{
            if((npiValideInfo == null || npi != npiValideInfo.checker.npi)){
                hasOngoingNpiRequest = true
                const { data } = await post(
                    "/oauth/verifier/disponibiliteNpi/",
                    {
                        type,
                        npi: npi,
                    }
                )
    
                hasOngoingNpiRequest = false
                npiValideInfo = data
            }
            const computedMd5 = md5(`${nom} ${prenom}`)
            return computedMd5 !== npiValideInfo.nomPrenomHash? 'Le nom et/ou le prénom saisi ne correspond pas aux informations du NPI.' : true
        } catch(err){
            console.log(err)
            hasOngoingNpiRequest = false
            return 'Erreur de vérification'
        }
    });

    extend("available", async function (value, params) {
        if (("" + value).length < 8) return true; //Inutile de vérifier si le nombre de caractère est inférieur à 8. Réduire les requêtes vers le serveur.
        let type = params[0]; // c, EMAIL, NPI,NPI_VALIDE
        
        const { data } = await post(
            "/oauth/verifier/disponibiliteIdentifiant/",
            {
                type,
                value,
            }
        );
        let message = data.available
            ? true
            : type == "PHONE"
            ? "Ce numéro de téléphone existe déjà"
            : type == "NPI" || type == "NPI_VALIDE"
            ? "Ce NPI a déjà été utilisé."
            :  "Cette adresse email existe déjà";
        if (!data.available && data.data != null) {
            const user = data.data;
            message =
                type == "PHONE"
                    ? `
        Ce numéro de téléphone existe déjà avec les informations suivantes: <br>
        <div class="mt-2 border border-danger p-2" style="border-radius: 8px;">
          <table class="table table-striped">
            <tbody>
              <tr><td>Nom: </td><td>${user.nom}</td></tr>
              <tr><td>Prénom: </td><td>${user.prenom}</td></tr>
              <tr><td>Phone: </td><td>${user.phone}</td></tr>
              <tr><td>Sexe: </td><td>${user.sexe}</td></tr>
              <tr><td>Date Naissance: </td><td>${user.dateNais.slice(
                  0,
                  10
              )}</td></tr>
              <tr><td>Departement: </td><td>${user.departement.nom}</td></tr>
              <tr><td>Commune: </td><td>${user.commune.nom}</td></tr>
              <tr><td>Village: </td><td>${user.ville.nom}</td></tr>
              <tr><td>Date d'inscription: </td><td>${user.createdDate.slice(
                  0,
                  10
              )}</td></tr>
            </tbody>
          </table>
        </div>
      `
                    : type == "NPI" || type == 'NPI_VALIDE'
                    ? `
      Ce NIP a déja été utilisé pour valider le DE ayant les informations suivantes: <br>
      <div class="mt-2 border border-danger p-2" style="border-radius: 8px;">
        <table class="table table-striped">
          <tbody>
            <tr><td>Nom: </td><td>${user.nom}</td></tr>
            <tr><td>Prénom: </td><td>${user.prenom}</td></tr>
            <tr><td>Phone: </td><td>${user.phone}</td></tr>
            <tr><td>Sexe: </td><td>${user.sexe}</td></tr>
            <tr><td>Date Naissance: </td><td>${user.dateNais.slice(
                0,
                10
            )}</td></tr>
            <tr><td>Departement: </td><td>${user.departement.nom}</td></tr>
            <tr><td>Commune: </td><td>${user.commune.nom}</td></tr>
            <tr><td>Village: </td><td>${user.ville.nom}</td></tr>
            <tr><td>NPI: </td><td>${user.npiValide}</td></tr>
            <tr><td>Date d'inscription: </td><td>${user.createdDate.slice(
                0,
                10
            )}</td></tr>
          </tbody>
        </table>
      </div>
    `
                    : "Cette adresse email existe déjà";
        }
        return message;
    });

    extend("availableMomo", async function (value, params) {
        if (("" + value).length < 8) return true; //Inutile de vérifier si le nombre de caractère est inférieur à 8. Réduire les requêtes vers le serveur.
        let type = params[0]; // PHONE, EMAIL, NPI
        let beneficiaireId = params[1];

        const { data } = await post(
            "/microprojets/verifier/disponibiliteIdentifiant/",
            {
                type,
                value,
                beneficiaireId
            }
        );
        let message = data.available
            ? true
            : type == "PHONE"
            ? "Ce numéro de téléphone existe déjà"
            : type == "NPI"
            ? "Ce NPI a déjà été utilisé."
            : "Cette adresse email existe déjà";
        if (!data.available && data.data != null) {
            const user = data.data;
            message =
                type == "PHONE"
                    ? `
        Ce numéro de téléphone existe déjà avec les informations suivantes: <br>
        <div class="mt-2 border border-danger p-2" style="border-radius: 8px;">
          <table class="table table-striped">
            <tbody>
              <tr><td>Nom: </td><td>${user.nom}</td></tr>
              <tr><td>Prénom: </td><td>${user.prenom}</td></tr>
              <tr><td>Phone: </td><td>${user.phone}</td></tr>
              <tr><td>Sexe: </td><td>${user.sexe}</td></tr>
              <tr><td>Date Naissance: </td><td>${user.dateNais.slice(
                  0,
                  10
              )}</td></tr>
              <tr><td>Departement: </td><td>${user.departement.nom}</td></tr>
              <tr><td>Commune: </td><td>${user.commune.nom}</td></tr>
              <tr><td>Village: </td><td>${user.ville.nom}</td></tr>
              <tr><td>Date d'inscription: </td><td>${user.createdDate.slice(
                  0,
                  10
              )}</td></tr>
            </tbody>
          </table>
        </div>
      `
                    : type == "NPI"
                    ? `
      Ce NIP a déja été utilisé pour valider le DE ayant les informations suivantes: <br>
      <div class="mt-2 border border-danger p-2" style="border-radius: 8px;">
        <table class="table table-striped">
          <tbody>
            <tr><td>Nom: </td><td>${user.nom}</td></tr>
            <tr><td>Prénom: </td><td>${user.prenom}</td></tr>
            <tr><td>Phone: </td><td>${user.phone}</td></tr>
            <tr><td>Sexe: </td><td>${user.sexe}</td></tr>
            <tr><td>Date Naissance: </td><td>${user.dateNais.slice(
                0,
                10
            )}</td></tr>
            <tr><td>Departement: </td><td>${user.departement.nom}</td></tr>
            <tr><td>Commune: </td><td>${user.commune.nom}</td></tr>
            <tr><td>Village: </td><td>${user.ville.nom}</td></tr>
            <tr><td>NPI: </td><td>${user.npiValide}</td></tr>
            <tr><td>Date d'inscription: </td><td>${user.createdDate.slice(
                0,
                10
            )}</td></tr>
          </tbody>
        </table>
      </div>
    `
                    : "Cette adresse email existe déjà";
        }
        return message;
    });

    extend("checkUserCanPass", async function (value, params) {
        let type = params[0]; //DE, AGENT, ENTREPRISE
        const { data } = await post("/oauth/verifier/canPass/", {
            type,
            value,
        });
        return data.canPass ? true : "Compte n'est pas un compte " + type;
    });
}
