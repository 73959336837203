<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <template>
          <ValidationProvider
            :rules="`required|numeric|exact:10${
              currentPhone != editableItem.phone ? '|available:PHONE' : ''
            }`"
            v-slot="{ errors }"
          >
            <div class="form-floating mb-3">
              <input
                v-model="editableItem.phone"
                type="text"
                class="form-control"
                id="phone"
                name="phone"
                :placeholder="`Votre téléphone`"
              />
              <label for="phone">Téléphone ({{ editableItem.nationalite.prefix }})</label>
              <small id="whatsappHelp" class="form-text"
                >Entrer le numéro sans l'indicatif ({{ editableItem.nationalite.prefix }})
                du {{ editableItem.nationalite.nom }}.</small
              ><br />
              <span
                v-html="err"
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
              ></span>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required|numeric|exact:8" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                v-model="editableItem.whatsapp"
                type="text"
                v-uppercase
                class="form-control"
                id="whatsapp"
                name="whatsapp"
                placeholder="Votre numéro whatsapp"
              />
              <label for="phone">Whatsapp</label>
              <small id="whatsappHelp" class="form-text"
                >Ce numéro peut être le même que votre numéro de téléphone.</small
              ><br />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
          <template v-if="editableItem.typeActivation != 'CAS_SPECIAL'">
            <ValidationProvider
              :rules="`required${
                currentEmail != editableItem.email ? '|available:EMAIL' : ''
              }`"
              v-slot="{ errors }"
            >
              <div class="form-floating mb-3">
                <input
                  v-model="editableItem.email"
                  type="email"
                  class="form-control"
                  id="email"
                  name="email"
                  placeholder="Votre email"
                />
                <label for="email">Adresse Email</label>
                <span
                  v-html="err"
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                ></span>
              </div>
            </ValidationProvider>
            <!-- <div class="row">
                  <div class="col-sm-6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <input
                          :value="editableItem.password"
                          @input="handleInput"
                          type="password"
                          class="form-control"
                          :class="{
                            'is-valid':
                              editableItem.password != undefined &&
                              editableItem.password == editableItem.password1,
                          }"
                          id="password"
                          name="password"
                          placeholder="Password"
                        />
                        <label for="password">Mot de passe</label>
                        <span
                          v-for="(err, index) in errors"
                          :key="index"
                          class="text-danger"
                          >{{ err }}</span
                        >
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <input
                          :value="editableItem.password1"
                          :class="{
                            'is-valid':
                              editableItem.password != undefined &&
                              editableItem.password == editableItem.password1,
                          }"
                          @input="handleInput"
                          type="password"
                          class="form-control"
                          id="password1"
                          name="password1"
                          placeholder="Password"
                        />
                        <label for="password1">Confirmer le mot de passe</label>
                        <span
                          v-for="(err, index) in errors"
                          :key="index"
                          class="text-danger"
                          >{{ err }}</span
                        >
                      </div>
                    </ValidationProvider>
                  </div>
                </div> -->
          </template>
        </template>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
export default {
  props: ["value", "currentEmail", "currentPhone"],
  components: {},
  mixins: [setepperFormMixin],
  data() {
    return {
      editableItem: this.value,
    };
  },
};
</script>

<style scoped></style>
