/**
 * Loads a script dynamically from a CDN.
 * @param {string} src - The URL of the script to load.
 * @param {Function} callback - Callback to execute after the script is loaded.
 */
function loadScript(src, callback) {
    if (document.querySelector(`script[src="${src}"]`)) {
      callback(); // Script already loaded
      return;
    }
  
    const script = document.createElement("script");
    script.src = src;
    script.onload = callback;
    script.onerror = (error) => {
      console.error("Failed to load script:", error);
      callback(error);
    };
    document.head.appendChild(script);
  }
  
  /**
   * Converts an HTML table to an Excel file and triggers a download.
   * @param {string} tableId - The ID of the table to export.
   * @param {string} fileName - The name of the Excel file to download.
   */
  function convertTableToExcel(tableId, fileName = "TableExport.xlsx") {
    const table = document.getElementById(tableId);
    if (!table) {
      console.error(`Table with ID "${tableId}" not found.`);
      return;
    }
  
    const worksheet = XLSX.utils.table_to_sheet(table);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, fileName);
  }
  
  export const loadXlsxScriptCdn = () => {
    loadScript("https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.18.5/xlsx.full.min.js", err => console.log(err))
  }

  /**
   * Exports an HTML table to an Excel file.
   * @param {string} tableId - The ID of the table to export.
   * @param {string} fileName - The name of the Excel file to download.
   * @param {Function} callback - Callback to execute after the export is complete.
   */
  export function exportTableToExcel(tableId, fileName = "TableExport.xlsx", callback) {
    // Check if XLSX is already loaded
    if (typeof window.XLSX === "undefined") {
      // Dynamically load the xlsx library from CDN
      loadScript("https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.18.5/xlsx.full.min.js", (error) => {
        if (error) {
          callback(error); // Pass the error to the callback
          return;
        }
  
        // Convert the table to Excel after the script is loaded
        convertTableToExcel(tableId, fileName);
        callback(null); // No error, operation successful
      });
    } else {
      // Convert the table to Excel immediately
      convertTableToExcel(tableId, fileName);
      callback(null); // No error, operation successful
    }
  }


export const download_table_as_xlsx =  exportTableToExcel
export const download_table_as_csv =  function (table_id = "id1", separator = ",", clbk) {
    exportTableToExcel(table_id, "data.xlsx", clbk)
}
export const download_table_as_csv_old =  function (table_id = "id1", separator = ",", clbk) {
    // Select rows from table_id
    var rows = document.querySelectorAll("table#" + table_id + " tr");
    // Construct csv
    var csv = [];
    //looping through the table
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");
      //looping through the tr
      for (var j = 0; j < cols.length; j++) {
        // removing space from the data
        var data = cols[j].innerText
          .replace(/(\r\n|\n|\r)/gm, "")
          .replace(/(\s\s)/gm, " ");
        // removing double qoute from the data
        data = data.replace(/"/g, `""`);
        // Push escaped string
        row.push(`"` + data + `"`);
      }
      csv.push(row.join(separator));
    }
    var csv_string = csv.join("\n");
    // Download it
    var filename =
      "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    if(clbk) clbk()
  }