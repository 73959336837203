// import axios from 'axios'
import { post, put, get} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

import ShortUniqueId from 'short-unique-id'

let shortId = new ShortUniqueId({ length: 24 });

export const actions = {
  fetchOffres({ commit, state }, params={}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/offres?${queryParams}`)
    .then(({ data }) =>{
      commit('addOffres', data)
    })
  },
  fetchOffres2({ commit, state }, params = {}){
    let { pageNumber=0, pageSize=state.pagination.size } = params
    return get(`/offres?size=${pageSize}&page=${pageNumber}&sort=libelle`)
    .then(({ data }) =>{
      commit('addConsignes', data)
    })
  },

  fetchOffresPublished({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/offres/published?${queryParams}`)
    .then(({ data }) =>{
      commit('addOffres', data)
      return data
    })
  },
  getOneOffrePublished({ commit },offreId){
    return get(`/offres/pushised/one/${offreId}`)
    .then(({ data }) =>{
      commit('setOffre', data)
      return data
    })
  },
  getOneOffre({ commit },offreId){
    console.log(offreId)
    return get(`/offres/${offreId}`)
    .then(({ data }) =>{
      commit('setOffre', data)
      return data
    })
  },
  checkIfCanPostOffre({ commit }){
    return get(`/offres/checkPeutPoster`)
    .then(({ data }) =>{
      return data
    })
  },
  tentativeDeCandidature({ commit }, { offreId, candidatInfo }){
    return put(`/offres/${offreId}/tentativeDeCandidature`, {
      ...candidatInfo,
      offreId
    })
    .then(({ data }) =>{
      return data
    })
  },
  fetchCandidatureNonEvaluesCount({ commit }, params = {}){
    const { offreId } = params
    return get(`/offres/${offreId}/candidatures/nonEvaluesCount`)
    .then(({ data }) =>{
      return data
    })
  },
  fetchOffresCount({ commit }, params = {}){
    const { offreId } = params

    return get(`/offres/offresCount`)
    .then(({ data }) =>{
      // commit('setOffresCount', data)
      return data
    })
  },
  updateOffreViewsCount({ commit }, params = {offreId}){
    const { offreId } = params
    let device = localStorage.getItem('deviceId')
    if(device == null){
      device = shortId()
      localStorage.setItem('deviceId', device)
    }
    return put(`/offres/${offreId}/updateViewsCount`, {
      device: device,
      offreId
    })
    .then(({ data }) =>{
      return data
    })
  },
  recommanderProfils({ commit }, params = {}){
    const { offreId, demandeurs } = params
    return post(`/offres/${offreId}/candidatures/recommanderProfils`, { offreId, demandeurs })
    .then(({ data }) =>{
      return data
    })
  },
  createOffre({ commit }, offre){
    return post('/offres', offre)
    .then(({ data }) =>{
      commit('addOffre', data)
    })
  },
  updateOffre({ commit }, offre){
    return put(`/offres/${offre.id}`, offre)
    .then(({ data }) =>{
      commit('replaceOffre', data)
    })
  },
  createOrUpdateOffre({ dispatch }, offre){
    if(offre.id !== undefined){
      return dispatch('updateOffre', offre)
    }
    else {
      return dispatch('createOffre', offre)
    }
  },
  selectoffre({ commit }, offre){
    commit('setOffre', offre)
  },
  validationOffre({ commit }, offre){
    return put(`/offres/validation/${offre.id}`, offre)
    .then(({ data }) =>{
      commit('replaceOffre', data)
    })
  },
  prolongerOffre({ commit }, offre){
    return put(`/offres/${offre.id}/prolonger`, offre)
    .then(({ data }) =>{
      commit('replaceOffre', data)
    })
  },
  cloturerOffre({ commit }, offre){
    return put(`/offres/${offre.id}/cloturer`, offre)
    .then(({ data }) =>{
      commit('replaceOffre', data)
    })
  },
  updateReconstitutionOffre({ commit }, offre){
    return put(`/offres/${offre.id}/ajusterDateReconstitution`, offre)
    .then(({ data }) =>{
      commit('replaceOffre', data)
    })
  },
  updateAnnulationOffre({ commit }, offre){
    return put(`/offres/${offre.id}/annuler`, offre)
    .then(({ data }) =>{
      commit('replaceOffre', data)
    })
  },
}