<template>
  <modal ref="modal" @save="save">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12">
            <ValidationProvider rules="required|email" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1"
                    ><i class="be bi-envelope"></i
                  ></span>
                  <input
                    :value="editableItem.email"
                    @input="handleInput"
                    type="text"
                    class="form-control"
                    id="email"
                    name="email"
                    placeholder="Boîte postale"
                  />
                </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <ValidationProvider rules="required|numeric|exact:10" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon2"
                    ><i class="bi bi-telephone"></i
                  ></span>
                  <input
                    :value="editableItem.phone"
                    @input="handleInput"
                    type="text"
                    id="phone"
                    name="phone"
                    class="form-control"
                    placeholder="Téléphone"
                    aria-label="Téléphone"
                    aria-describedby="basic-addon2"
                  />
                </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <ValidationProvider rules="required|min:10" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon3"
                    ><i class="bi bi-whatsapp"></i
                  ></span>
                  <input
                    :value="editableItem.whatsapp"
                    @input="handleInput"
                    type="number"
                    id="whatsapp"
                    name="whatsapp"
                    class="form-control"
                    placeholder="Whatsapp"
                  />
                </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  props: {
    item: {
      type: Object,
      require: true,
    },
  },
  mixins: [],
  components: { Modal },
  data() {
    return {
      editableItem: this.item,
    };
  },
  created() {
    // this.editableItem = { ...this.item }
  },
  methods: {
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    show() {
      this.$refs.modal.show();
    },
    save() {
      this.$emit("save", this.editableItem);
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
