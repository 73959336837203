<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <form ref="form" method="post" @submit.prevent="handleSubmit()">
      <div class="row">
        <div class="col-sm-6">
          <ValidationProvider rules="required|numeric|exact:10" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.phone"
                @input="handleInput"
                type="text"
                id="phone"
                name="phone"
                class="form-control"
                placeholder="Téléphone"
                aria-label="Téléphone"
                aria-describedby="basic-addon2"
              />
              <label for="phone">Téléphone</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-6">
          <ValidationProvider rules="required|numeric|exact:10" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.whatsapp"
                @input="handleInput"
                type="number"
                id="whatsapp"
                name="whatsapp"
                class="form-control"
                placeholder="Whatsapp"
              />
              <label for="whatsapp">Whatsapp</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>

        <div class="col-sm-6">
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.codePostal"
                @input="handleInput"
                type="text"
                class="form-control"
                id="codePostal"
                name="codePostal"
                placeholder="Boîte postale"
              />
              <label for="codePostal">Boîte postal</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <!-- <div class="col-sm-6">
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.siteWeb"
                @input="handleInput"
                type="text"
                class="form-control"
                id="siteWeb"
                name="siteWeb"
                placeholder="Site web"
              />
              <label for="siteWeb">Site web</label>
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
        </div> -->
        <div class="col-sm-12">
          <ValidationProvider rules="required|email" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.email"
                @input="handleInput"
                type="text"
                class="form-control"
                id="email"
                name="email"
                placeholder="Votre email"
              />
              <label for="email">Votre email</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <textarea
                :value="editableItem.adresse"
                @input="handleInput"
                class="form-control"
                id="adresse"
                name="adresse"
                placeholder="Adresse physique"
                rows="3"
              />
              <label for="adresse">Adresse physique</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  props: ["value"],
  mixins: [],
  data() {
    return {
      editableItem: this.value,
    };
  },
  created() {
    // this.editableItem = { ...this.item }
  },
  methods: {
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    show() {
      this.$refs.modal.show();
    },
    save() {
      this.$emit("save", this.editableItem);
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
