import { render, staticRenderFns } from "./CandidatureList.vue?vue&type=template&id=48370cc7&"
import script from "./CandidatureList.vue?vue&type=script&lang=js&"
export * from "./CandidatureList.vue?vue&type=script&lang=js&"
import style0 from "./CandidatureList.vue?vue&type=style&index=0&id=48370cc7&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports