<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <template v-if="editableItem.typeActivation != 'CAS_SPECIAL'">
          <ValidationProvider
            rules="required|numeric|available:PHONE|min:10"
            v-slot="{ errors }"
          >
            <div class="form-floating mb-3">
              <input
                :value="editableItem.phone"
                @input="handleInput"
                type="text"
                class="form-control"
                id="phone"
                name="phone"
                :placeholder="`Votre téléphone`"
              />
              <label for="phone"
                >Téléphone
                <span v-if="editableItem.nationalite"
                  >({{ editableItem.nationalite.prefix }})</span
                ></label
              >
              <small v-if="editableItem.nationalite" id="phoneHelp" class="form-text"
                >Entrer le numéro sans l'indicatif ({{ editableItem.nationalite.prefix }})
                du {{ editableItem.nationalite.nom }}.</small
              ><br />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required|numeric|min:10" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.whatsapp"
                @input="handleInput"
                type="text"
                class="form-control"
                id="whatsapp"
                name="whatsapp"
                placeholder="Votre numéro whatsapp"
              />
              <label for="whatsapp">Whatsapp</label>
              <small id="whatsappHelp" class="form-text"
                >Ce numéro peut être le même que votre numéro de téléphone.</small
              ><br />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
          <template v-if="editableItem.typeActivation != 'CAS_SPECIAL'">
            <ValidationProvider rules="required|available:EMAIL" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.email"
                  @input="handleInput"
                  type="email"
                  class="form-control"
                  id="email"
                  name="email"
                  placeholder="Votre email"
                />
                <label for="email">Adresse Email</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
            <!-- <div class="row">
                <div class="col-sm-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        :value="editableItem.password"
                        @input="handleInput"
                        type="password"
                        class="form-control"
                        :class="{
                          'is-valid':
                            editableItem.password != undefined &&
                            editableItem.password == editableItem.password1,
                        }"
                        id="password"
                        name="password"
                        placeholder="Password"
                      />
                      <label for="password">Mot de passe</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        :value="editableItem.password1"
                        :class="{
                          'is-valid':
                            editableItem.password != undefined &&
                            editableItem.password == editableItem.password1,
                        }"
                        @input="handleInput"
                        type="password"
                        class="form-control"
                        id="password1"
                        name="password1"
                        placeholder="Password"
                      />
                      <label for="password1">Confirmer le mot de passe</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </div> -->
          </template>
        </template>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
export default {
  props: ["value"],
  components: {},
  mixins: [setepperFormMixin],
  data() {
    return {
      editableItem: this.value,
    };
  },
};
</script>

<style scoped></style>
