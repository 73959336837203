export const API_URL = "http://localhost:8000/api";
// export const API_URL = 'https://sica-api.anpe.bj/api'
// export const SOCKET_URL = 'ws://localhost:8000'
// export const API_URL = 'http://185.170.214.22:8077/api'


export const FILE_UPLOAD_API_URL = API_URL + "/medias/upload";
export const FILE_DOWNLOAD_API_URL = API_URL + "/medias/upload";
export const ENCRYPTED_FILE_UPLOAD_API_URL = API_URL + "/s-medias/download";
export const ENCRYPTED_FILE_DOWNLOAD_API_URL = API_URL + "/s-medias/download";

// Suppression des logs
if (API_URL.startsWith("https://") ) {
    console.log = function () {};
    console.error = function () {};
}



export const paginationParamNames = {
    page: "page",
    rowsPerPage: "size",
    totalCount: "totalElements",
    pageCount: "totalPages",
    sortBy: "sort",
};
