<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <ValidationProvider
          name="npi"
          rules="required|numeric|exact:10|available:NPI"
          v-slot="{ errors }"
        >
          <div class="form-floating mb-3">
            <input
              :value="editableItem.npi"
              @input="handleInput"
              required
              type="text"
              v-uppercase
              class="form-control"
              id="npi"
              name="npi"
              placeholder="Votre npi"
            />
            <label for="nom">NPI</label>
            <span
              v-for="(err, index) in errors"
              :key="index"
              class="text-danger"
              v-html="err"
            ></span>
          </div>
        </ValidationProvider>
        <ValidationProvider
          name="nom"
          :mode="custom"
          :rules="`required|npiValidite:NPI,@npi,@nom,@prenom`"
          v-slot="{ errors }"
        >
          <div class="form-floating mb-3">
            <input
              :value="editableItem.nom"
              @input="handleInput"
              type="text"
              v-uppercase
              class="form-control"
              id="nom"
              name="nom"
              placeholder="Votre nom"
            />
            <label for="nom">Nom</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </div>
        </ValidationProvider>
        <ValidationProvider
          name="prenom"
          :rules="`required|npiValidite:NPI,@npi,@nom,@prenom`"
          v-slot="{ errors }"
        >
          <div class="form-floating mb-3">
            <input
              :value="editableItem.prenom"
              @input="handleInput"
              type="text"
              v-uppercase
              class="form-control"
              id="prenom"
              name="prenom"
              placeholder="Votre prénom"
            />
            <label for="prenom">Prénom</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </div>
        </ValidationProvider>
        <ValidationProvider rules="numeric|exact:13" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <input
              :value="editableItem.ifu"
              @input="handleInput"
              type="text"
              class="form-control"
              id="ifu"
              name="ifu"
              placeholder="Votre IFU"
            />
            <label for="ifu">IFU</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </div>
        </ValidationProvider>
        <div class="row">
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <select
                  :value="editableItem.sexe"
                  @input="handleInput"
                  class="form-select form-select mb-4"
                  id="sexe"
                  name="sexe"
                  aria-label="Votre sexe"
                >
                  <option value="M">Masculin</option>
                  <option value="F">Féminin</option>
                </select>
                <label for="sexe">Sexe</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="required|minAge:18" v-slot="{ errors }">
              <div class="form mb-3">
                <!-- <b-form-datepicker
                size="sm"
                locale="fr"
                v-model="dateNais"
                name="dateNais"
                :maxDate="maxDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                class="form-control pb-0"
               /> -->

                <label for="dateNais">Date de naissance</label>
                <p-calendar
                  id="dateNais"
                  class="col-12 md:col-12"
                  :showIcon="true"
                  size="sm"
                  locale="fr"
                  v-model="editableItem.dateNais"
                  name="dateNais"
                  :manualInput="true"
                  dateFormat="dd/mm/yy"
                ></p-calendar>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      editableItem: this.value,
      dateNais: this.value.dateNais,
    };
  },
  computed: {
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      today.setFullYear(now.getFullYear() - 18);
      return today;
    },
  },
  watch: {
    dateNais() {
      // this.editableItem.dateNais = this.$moment(val).format('DD-MM-YYYY')
      this.$emit("input", this.editableItem);
    },
  },
  methods: {
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
  },
};
</script>

<style scoped></style>
