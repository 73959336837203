<template>
  <div>
    <p-progress-bar v-if="loading" style="height: 0.33em" mode="indeterminate" />
    <ValidationObserver
      ref="observer"
      v-slot="{ handleSubmit }"
      v-if="editableItem.beneficiaire != null"
    >
      <form ref="form formgrid" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <div class="col-sm-12">
            <h5>Informations sur le promoteur/la promotrice</h5>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  v-model="editableItem.beneficiaire.nom"
                  type="text"
                  v-uppercase
                  disabled
                  class="form-control"
                  id="nom"
                  name="nom"
                  placeholder="Nom"
                />
                <label for="nom">Nom</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  v-model="editableItem.beneficiaire.prenom"
                  type="text"
                  v-uppercase
                  class="form-control"
                  id="prenom"
                  disabled
                  name="prenom"
                  placeholder="Prénom"
                />
                <label for="prenom">Prénom</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <ValidationProvider rules="" v-slot="{ errors }">
              <!-- <div class="form-floating mb-3">
                <select
                  v-model="editableItem.beneficiaire.sexe"
                  class="form-select form-select mb-4"
                  id="sexe"
                  name="sexe"
                  aria-label="Votre sexe"
                >
                  <option value="M">Masculin</option>
                  <option value="F">Féminin</option>
                </select>
                <label for="sexe">Sexe</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div> -->
              <div class="form-floating mb-3">
                <input
                  v-model="editableItem.beneficiaire.sexe"
                  type="text"
                  v-uppercase
                  class="form-control"
                  id="sexe"
                  disabled
                  name="sexe"
                  placeholder="Sexe"
                />
                <label for="sexe">Sexe</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>

          <div class="col-sm-6">
            <ValidationProvider rules="" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="
                    editableItem.beneficiaire.niveauInstruction
                      ? editableItem.beneficiaire.niveauInstruction.description
                      : 'AUCUN'
                  "
                  type="text"
                  v-uppercase
                  disabled
                  id="niveauInstructionEnquete"
                  name="niveauInstructionEnquete"
                  class="form-control"
                  placeholder="Niveau d'instruction"
                />
                <label for="niveauInstructionEnquete">Niveau d'instruction</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  v-model="editableItem.beneficiaire.situationMatrimoniale"
                  type="text"
                  v-uppercase
                  disabled
                  class="form-control"
                  id="situationMatrimoniale"
                  name="situationMatrimoniale"
                  placeholder="Situation Matrimoniale"
                />
                <label for="situationMatrimoniale">Situation Matrimoniale</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>

          <div class="col-sm-6">
            <ValidationProvider rules="" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  v-model="editableItem.beneficiaire.npi"
                  type="text"
                  v-uppercase
                  class="form-control"
                  id="npi"
                  disabled
                  name="npi"
                  placeholder="Numéro Personnel d’Identification (NPI)"
                />
                <label for="npi">Numéro Personnel d’Identification (NPI)</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  v-model="editableItem.nombrePersonneACharge"
                  type="number"
                  class="form-control"
                  id="nombrePersonneACharge"
                  name="nombrePersonneACharge"
                  placeholder="Nombre de personnes à charge"
                />
                <label for="nombrePersonneCharge">Nombre de personnes à charge</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <h5>Information mobile money du promoteur/la promotrice</h5>
          </div>
          <div class="col-sm-6">
            <ValidationProvider
              :rules="`required|numeric|availableMomo:PHONE,${beneficiaireId}|exact:10`"
              v-slot="{ errors }"
            >
              <div class="form-floating mb-3">
                <input
                  v-model="editableItem.momoPhone"
                  type="text"
                  class="form-control"
                  id="momoPhone"
                  name="momoPhone"
                  placeholder="Numéro de téléphone (Mobile Money)"
                />
                <label for="momoPhone">Numéro Mobile Money)</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  v-html="err"
                ></span>
              </div>
            </ValidationProvider>
          </div>

          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <c-select
                  id="operateurGsm"
                  name="operateurGsm"
                  option-label="label"
                  option-value="code"
                  :emitObject="false"
                  v-model="editableItem.operateurGsm"
                  :options="operateurs"
                  class="form-select"
                >
                </c-select>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
                <label for="operateurGsm">Opérateur GSM</label>
              </div>
            </ValidationProvider>
          </div>
        </div>

        <!-- <div class="row mb-4">
          <h5 class="mb-2">Localisation du site du microprojet</h5>
          <Echelon disabled v-model="editableItem" />
        </div> -->

        <div class="row">
          <div class="col-sm-12">
            <h5>Localisation du site du microprojet</h5>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.beneficiaire.departement.nom"
                  type="text"
                  v-uppercase
                  disabled
                  id="departement"
                  name="departement"
                  class="form-control"
                  placeholder="Département"
                />
                <label for="departement">Département</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>

          <div class="col-sm-6">
            <ValidationProvider rules="" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.beneficiaire.commune.nom"
                  type="text"
                  v-uppercase
                  disabled
                  id="commune"
                  name="commune"
                  class="form-control"
                  placeholder="Commune"
                />
                <label for="commune">Commune</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>

          <div class="col-sm-6">
            <ValidationProvider rules="" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.beneficiaire.arrondissement.nom"
                  type="text"
                  v-uppercase
                  disabled
                  id="arrondissement"
                  name="arrondissement"
                  class="form-control"
                  placeholder="Arrondissement"
                />
                <label for="arrondissement">Arrondisement</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>

          <div class="col-sm-6">
            <ValidationProvider rules="" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.beneficiaire.ville.nom"
                  type="text"
                  v-uppercase
                  disabled
                  id="ville"
                  name="ville"
                  class="form-control"
                  placeholder="Village/Quartier de ville"
                />
                <label for="arrondissement">Village/Quartier de ville</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <h5>Personne contact </h5>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  v-model="editableItem.beneficiaire.azoli.personneNom"
                  type="text"
                  v-uppercase
                  disabled
                  id="personneNom"
                  name="personneNom"
                  class="form-control"
                  placeholder="Nom"
                />
                <label for="personneNom">Nom</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  v-model="editableItem.beneficiaire.azoli.personnePrenom"
                  type="text"
                  v-uppercase
                  disabled
                  class="form-control"
                  id="personnePrenom"
                  name="personnePrenom"
                  placeholder="Prénom"
                />
                <label for="personnePrenom">Prénom</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="|exact:10" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.beneficiaire.azoli.personneContact"
                  type="number"
                  id="personneContact"
                  name="personneContact"
                  disabled
                  class="form-control"
                  placeholder="Téléphone"
                />
                <label for="personneContact">Téléphone</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Echelon from "@/components/common/echelon/Echelon.vue";
import MetierSelect from "@/components/common/MetierSelect.vue";
import NaemaSelect from "@/components/common/NaemaSelect.vue";
import { mapActions, mapGetters } from "vuex";
import { stepperFormMixin } from "../mixins/stepper-form-mixin";

export default {
  props: ["value"],
  components: {
    Echelon,
    MetierSelect,
    NaemaSelect,
  },
  mixins: [stepperFormMixin],
  data() {
    return {
      loading: false,
      editableItem: {
        ...this.value,
      },
      operateurs: [
        {
          code: null,
          label: "Sélectionner un Réseau",
        },
        {
          code: "MTN",
          label: "MTN",
        },
        {
          code: "MOOV",
          label: "MOOV",
        },
        {
          code: "CELTIIS",
          label: "CELTIIS",
        },
      ],
    };
  },
  created() {
    this.fetchProgrammes();
  },
  //   watch: {
  //     editableItem: {
  //       handler() {
  //         console.log(this.value);
  //         if (
  //           this.value &&
  //           this.value.beneficiaire == null &&
  //           this.value.beneficiaireId != null
  //         ) {
  //           alert("IN");
  //           alert(this.value.beneficiaireId);
  //         }
  //         // this.loading = true;
  //         // this.fetchDossier({
  //         //   demandeurId: this.value.beneficiaireId,
  //         // })
  //         //   .then((data) => {
  //         //     alert("ds");
  //         //     console.log(data);
  //         //     this.value.beneficiaire = data;
  //         //     this.loading = false;
  //         //   })
  //         //   .catch(() => (this.loading = false));
  //       },
  //       immediate: true,
  //       deep: true,
  //     },
  //   },
  computed: {
    ...mapGetters({
      programmes: "programme/programmes",
    }),
    beneficiaireId() {
      return this.editableItem.beneficiaire != null
        ? this.editableItem.beneficiaire.id
        : this.editableItem.beneficiaireId;
    },
    totalApportPersonnel() {
      return (
        parseInt(this.editableItem.partInverstissementPropre || 0) +
        parseInt(this.editableItem.partFonctionnementPropre || 0)
      );
    },
    totalSubvention() {
      return (
        parseInt(this.editableItem.partInverstissementSubvention || 0) +
        parseInt(this.editableItem.partFonctionnementSubvention || 0)
      );
    },
    totalCredit() {
      return (
        parseInt(this.editableItem.partInverstissementMicroprojet || 0) +
        parseInt(this.editableItem.partFonctionnementMicroprojet || 0)
      );
    },
    totalInvestissement() {
      return (
        parseInt(this.editableItem.partInverstissementPropre || 0) +
        parseInt(this.editableItem.partInverstissementSubvention || 0) +
        parseInt(this.editableItem.partInverstissementMicroprojet || 0)
      );
    },
    totalFonctionnement() {
      return (
        parseInt(this.editableItem.partFonctionnementPropre || 0) +
        parseInt(this.editableItem.partFonctionnementSubvention || 0) +
        parseInt(this.editableItem.partFonctionnementMicroprojet || 0)
      );
    },
    totalProjet() {
      return this.totalInvestissement + this.totalFonctionnement;
    },
    beneficeBrutPrevisionelleSur6Mois() {
      return (
        parseInt(this.editableItem.recettePrevisionelleSur6Mois || 0) -
        parseInt(this.editableItem.chargePrevisionelleSur6Mois || 0)
      );
    },
    tauxBrutRentabilite() {
      return (
        (parseInt(this.editableItem.recettePrevisionelleSur6Mois || 0) != 0
          ? this.beneficeBrutPrevisionelleSur6Mois /
            parseInt(this.editableItem.recettePrevisionelleSur6Mois || 0)
          : 0) * 100
      );
    },
  },
  methods: {
    ...mapActions({
      fetchProgrammes: "programme/fetchProgrammes",
      fetchDossier: "demandeur/fetchDossier",
      createOrUpdateMicroprojet: "microprojet/createOrUpdateMicroprojet",
    }),
    async save() {
      if (!(await this.isCompleted())) return;
      const data = await this.createOrUpdateMicroprojet(this.editableItem);
      this.$emit("save", { ...this.editableItem, ...data });
    },
  },
};
</script>

<style>
.field {
  margin-bottom: 10px;
}
</style>
