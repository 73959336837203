<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <form ref="form" method="post" @submit.prevent="handleSubmit()">
      <h6 class="mt-3 mb-2">Contact de l'entreprise</h6>
      <div class="row">
        <div class="col-sm-6">
          <ValidationProvider
            :rules="
              'numeric|' + editableItem.id == null
                ? 'required|available:PHONE|exact:10'
                : 'required|exact:10'
            "
            v-slot="{ errors }"
          >
            <div class="form-floating mb-3">
              <input
                :value="editableItem.phone"
                @input="handleInput"
                type="text"
                id="phone"
                name="phone"
                class="form-control"
                placeholder="telephone"
                aria-label="Téléphone"
                aria-describedby="basic-addon2"
              />
              <label for="telephone">Téléphone</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>

        <div class="col-sm-6">
          <!-- <ValidationProvider rules="" v-slot="{ errors }"> -->
          <div class="form-floating mb-3">
            <input
              v-uppercase
              :value="editableItem.whatsapp"
              @input="handleInput"
              type="text"
              id="whatsapp"
              name="whatsapp"
              class="form-control"
              placeholder="Whatsapp"
            />
            <label for="whatsapp">Whatsapp</label>
            <!-- <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              > -->
          </div>
          <!-- </ValidationProvider> -->
        </div>
        <div class="col-sm-6">
          <!-- <ValidationProvider rules="required" v-slot="{ errors }"> -->
          <div class="form-floating mb-3">
            <input
              :value="editableItem.siteWeb"
              @input="handleInput"
              type="text"
              v-uppercase
              class="form-control"
              id="siteWeb"
              name="siteWeb"
              placeholder="Votre site webl"
            />
            <label for="siteWeb">Site web</label>
            <!-- <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span> -->
          </div>
          <!-- </ValidationProvider> -->
        </div>
        <div class="col-sm-6">
          <ValidationProvider
            :rules="
              editableItem.id != undefined
                ? 'required|email'
                : 'required|available:EMAIL|email'
            "
            v-slot="{ errors }"
          >
            <div class="form-floating mb-3">
              <input
                :disabled="editableItem.id != undefined"
                :value="editableItem.email"
                @input="handleInput"
                type="text"
                class="form-control"
                id="email"
                name="email"
                placeholder="Votre email"
              />
              <label for="email">Votre email</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <h6 class="mt-3 mb-2">Responsable de la société</h6>
      <div class="row">
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.nomDirigeant"
                @input="handleInput"
                type="text"
                v-uppercase
                id="nomDirigeant"
                name="nomDirigeant"
                class="form-control"
                placeholder="Nom du nom du dirigenat"
              />
              <label for="nomDirigeant">Nom du dirigeant</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-6">
          <ValidationProvider rules="required|numeric|exact:10" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.telephoneDirigeant"
                @input="handleInput"
                type="text"
                v-uppercase
                class="form-control"
                id="telephoneDirigeant"
                name="telephoneDirigeant"
                placeholder="N° telephone du dirigeant"
              />
              <label for="telephoneDirigeant">Téléphone du dirigeant</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-6">
          <ValidationProvider rules="required|min:8" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.numeroWhatsappDirigent"
                @input="handleInput"
                type="number"
                v-uppercase
                id="numeroWhatsappDirigent"
                name="numeroWhatsappDirigent"
                class="form-control"
                placeholder="N° Whatsapp"
              />
              <label for="numeroWhatsappDirigent">N° whatsapp</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <h6 class="mt-3 mb-2">Personne contact (personne à contacter en cas de besoin)</h6>
      <div class="row">
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.nomContact"
                @input="handleInput"
                type="text"
                v-uppercase
                id="nomContact"
                name="nomContact"
                class="form-control"
                placeholder="Nom"
              />
              <label for="nomContact">Nom</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-6">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.fonction"
                @input="handleInput"
                type="text"
                v-uppercase
                id="fonction"
                name="fonction"
                class="form-control"
                placeholder="fonction"
              />
              <label for="fonction">Fonction</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-6">
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.emailContact"
                @input="handleInput"
                type="text"
                id="emailContact"
                name="emailContact"
                class="form-control"
                placeholder="email"
              />
              <label for="emailContact">Email</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-6">
          <ValidationProvider rules="required|numeric|exact:8" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.telephonePersonneContact"
                @input="handleInput"
                type="text"
                class="form-control"
                id="telephonePersonneContact"
                name="telephonePersonneContact"
                placeholder="N° telephone du personne à contacter"
              />
              <label for="telephonePersonneContact"
                >Téléphone du personne à contacter</label
              >
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-6">
          <ValidationProvider rules="required|min:8" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.numeroWhatsappPersonneContact"
                @input="handleInput"
                type="number"
                id="numeroWhatsappPersonneContact"
                name="numeroWhatsappPersonneContact"
                class="form-control"
                placeholder="N° Whatsapp"
              />
              <label for="numeroWhatsappPersonneContact">N° whatsapp</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <!-- {{ editableItem }} -->
      <!-- <domicile></domicile>-->
    </form>
  </ValidationObserver>
</template>

<script>
// import CSelect from "@/components/CSelect.vue";
import { mapActions, mapGetters } from "vuex";
// import domicile from "./Domicile.vue";

export default {
  props: ["value"],
  mixins: [],
  components: {
    // CSelect,
    // domicile,
  },
  data() {
    return {
      editableItem: {},
      // contacts: { ...this.value },:
    };
  },
  created() {
    this.editableItem = { ...this.value };
    this.fetchTypeParentes();
  },
  watch: {
    value() {
      this.editableItem = { ...this.value };
    },
  },

  computed: {
    ...mapGetters({
      typeParentes: "parente/typeParentes",
    }),
  },
  methods: {
    ...mapActions({
      fetchTypeParentes: "parente/fetchTypeParentes",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;

      this.$emit("input", {
        ...this.value,
        ...this.editableItem,
        intitule: this.value.raisonSocial,
      });
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    show() {
      this.$refs.modal.show();
    },
    save() {
      this.$emit("save", {
        ...this.value,
        contacts: this.editableItem,
      });
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
