<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <form ref="form" method="post" @submit.prevent="handleSubmit()">
      <div class="row">
        <div class="col-sm-6">
          <ValidationProvider rules="required|numeric|exact:10" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.phone"
                @input="handleInput"
                type="text"
                id="phone"
                name="phone"
                class="form-control"
                placeholder="Téléphone"
                aria-label="Téléphone"
                aria-describedby="basic-addon2"
              />
              <label for="phone">Téléphone</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-6">
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.phoneAlternatif"
                @input="handleInput"
                type="number"
                class="form-control"
                id="phoneAlternatif"
                name="phoneAlternatif"
                placeholder="Téléphone alternatif"
              />
              <label for="phoneAlternatif">Téléphone alternatif</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-6">
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.whatsapp"
                @input="handleInput"
                type="text"
                id="whatsapp"
                name="whatsapp"
                class="form-control"
                placeholder="Whatsapp"
              />
              <label for="whatsapp">Whatsapp</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-6">
          <ValidationProvider rules="required|email" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.email"
                @input="handleInput"
                type="text"
                v-uppercase
                class="form-control"
                id="email"
                name="email"
                placeholder="Votre email"
              />
              <label for="email">Votre email</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <h6 class="mt-3 mb-2">Personne contact (personne à contacter en cas de besoin)</h6>
      <div class="row">
        <div class="col-sm-6">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.personneNom"
                @input="handleInput"
                type="text"
                v-uppercase
                id="personneNom"
                name="personneNom"
                class="form-control"
                placeholder="Nom"
              />
              <label for="personneNom">Nom</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-6">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.personnePrenom"
                @input="handleInput"
                type="text"
                v-uppercase
                class="form-control"
                id="personnePrenom"
                name="personnePrenom"
                placeholder="Prénom"
              />
              <label for="personnePrenom">Prénom</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-6">
          <ValidationProvider rules="required|min:8" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.personneContact"
                @input="handleInput"
                type="number"
                id="personneContact"
                name="personneContact"
                class="form-control"
                placeholder="Téléphone"
              />
              <label for="personneContact">Téléphone</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-6">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <!-- <select :value="editableItem.niveau" @input="handleInput" class="form-select" id="niveau" name="niveau" aria-label="Votre niveau">
                <option :value="niveau.id" v-for="niveau in niveaux" :key="niveau.id">{{ niveau.libelle }}</option>
              </select> -->
              <c-select
                id="personneParente"
                name="personneParente"
                option-label="libelle"
                option-value="id"
                v-model="editableItem.personneParente"
                :options="typeParentes"
                class="form-select"
              >
              </c-select>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
              <label for="personneParente">Lien de parenté</label>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import CSelect from "@/components/CSelect.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["value"],
  mixins: [],
  components: {
    CSelect,
  },
  data() {
    return {
      editableItem: { ...this.value },
    };
  },
  created() {
    // this.editableItem = { ...this.item }
    this.fetchTypeParentes();
  },
  computed: {
    ...mapGetters({
      typeParentes: "parente/typeParentes",
    }),
  },
  methods: {
    ...mapActions({
      fetchTypeParentes: "parente/fetchTypeParentes",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    show() {
      this.$refs.modal.show();
    },
    save() {
      this.$emit("save", this.editableItem);
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
