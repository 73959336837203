<template>
  <div>
    <PDialog
      :header="title"
      :modal="true"
      :visible.sync="display"
      :contentStyle="{ overflow: 'visible' }"
      :containerStyle="{ width: '40vw' }"
    >
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div class="card mt border-0">
          <form ref="form" method="post" @submit.prevent="handleSubmit()">
            <template>
              <div class="row">
                <div class="col-sm-12 field mb-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div>Date de prise de service</div>
                    <p-calendar
                      class="col-12 md:col-12"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      name="datePriseService"
                      :manualInput="false"
                      v-model="editableItem.datePriseService"
                      :showWeek="true"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="col-sm-12">
                  <ValidationProvider
                    rules="required|numeric|exact:10"
                    v-slot="{ errors }"
                  >
                    <div>Numéro MOMO du DE</div>

                    <div class="form-floatin mb-3">
                      <input
                        v-model="editableItem.momoPhone"
                        type="text"
                        id="momoPhone"
                        name="momoPhone"
                        class="form-control"
                        aria-label="Numéro MOMO du DE"
                      />
                      <!-- <label for="phone">Numéro MOMO du DE</label> -->
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <c-select
                        id="operateurGsm"
                        name="operateurGsm"
                        option-label="label"
                        option-value="code"
                        :emitObject="false"
                        v-model="editableItem.operateurGsm"
                        :options="operateurs"
                        class="form-select"
                      >
                      </c-select>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                      <label for="operateurGsm">Opérateur GSM</label>
                    </div>
                  </ValidationProvider>
                </div>
                <!-- <div class="col-sm-12 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div>Date de prise de service</div>
                    <p-calendar
                      class="col-12 md:col-12"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      name="datePriseService"
                      :manualInput="false"
                      v-model="editableItem.datePriseService"
                      :showWeek="true"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div> -->
                <!-- <div class="col-sm-12 field">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="mt-2">Provenance</div>
                      <Echelon v-model="editableItem" />
                      <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                    </ValidationProvider>
                  </div> -->
              </div>
              <div class="col-sm-12 field mt-3">
                <ValidationProvider rules="" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <textarea
                      type="text"
                      rows="10"
                      style="height: 120px"
                      id="observation"
                      class="form-control"
                      name="observation"
                      v-model="editableItem.observation"
                    />
                    <label for="observation">Observation</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
              </div>
            </template>
          </form>
        </div>
      </ValidationObserver>
      <template #footer>
        <PButton
          label="Quitter"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { mapActions, mapGetters } from "vuex";
import Echelon from "../../../common/echelon/Echelon.vue";

export default {
  components: {
    Echelon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [paginatorMixin],
  data() {
    return {
      display: false,
      editableItem: {},
      operateurs: [
        {
          code: null,
          label: "Sélectionner un Réseau",
        },
        {
          code: "MTN",
          label: "MTN",
        },
        {
          code: "MOOV",
          label: "MOOV",
        },
        {
          code: "CELTIIS",
          label: "CELTIIS",
        },
      ],
    };
  },
  watch: {
    item: {
      handler() {
        this.editableItem = { ...this.item };
      },
      deep: true,
    },
  },
  created() {},
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({
      fetchTypePlacements: "typePlacement/fetchTypePlacements",
      fetchNatureContrats: "natureContrat/fetchNatureContrats",
    }),
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;
      this.$emit("save", {
        ...this.editableItem,
        communeProvenance: this.editableItem.commune,
      });
      this.editableItem = {};
      this.hide();
    },
  },
};
</script>
