<template>
  <PDialog
    class="stepper-dialog"
    :header="title"
    :modal="true"
    :visible.sync="displayDialog"
    :contentStyle="{ overflow: '' }"
    :containerStyle="{ width: '70vw' }"
  >
    <template #header>
      <div class="card px-0 border-0 mb-2 w-100">
        <div>
          <h4 class="d-flex justify-content-between">
            <div>
              {{ title }}
            </div>
            <PButton
              @click="cancel()"
              icon="pi pi-times"
              class="p-button-rounded p-button-outlined p-button-danger"
            />
          </h4>
          <PDivider />
        </div>
        <DStepper
          class="shadow-light"
          :steps="steps"
          :activeStepIndex="activeStepIndex"
          :readonly="false"
        />
      </div>
    </template>

    <template>
      <ValidationObserver
        v-if="activeStep.name == 'INFORMARTION_DE_BASE'"
        ref="observer0"
        v-slot="{ handleSubmit }"
      >
        <form ref="form0" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    v-model="editableItem.lieu"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="lieu"
                    name="lieu"
                    placeholder="lieu"
                  />
                  <label for="lieu">Lieu</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>

            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    v-model="editableItem.raisonSocial"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="raisonSocial"
                    name="raisonSocial"
                    placeholder="Nom de la société"
                  />
                  <label for="nomDirigeant">Nom de la société</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>

            <div class="col-sm-6">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    v-model="editableItem.nomDirigeant"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="nomDirigeant"
                    name="nomDirigeant"
                    placeholder="Nom Dirigeant"
                  />
                  <label for="nomDirigeant">Nom Dirigeant</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>

            <div class="col-sm-6">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    v-model="editableItem.adresseSiege"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="adresseSiege"
                    name="adresseSiege"
                    placeholder="Siège de la société"
                  />
                  <label for="adresseSiege">Siège de la société</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-4">
              <ValidationProvider rules="required|email" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    v-model="editableItem.courriel"
                    type="text"
                    class="form-control"
                    id="courriel"
                    name="courriel"
                    placeholder="Couriel de la société"
                  />
                  <label for="nomDirigeant">Couriel de la société</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-4">
              <ValidationProvider rules="required|numeric|exact:10" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    v-model="editableItem.phone"
                    type="text"
                    class="form-control"
                    id="phone"
                    name="phone"
                    placeholder="Téléphone de la société"
                  />
                  <label for="phone">Téléphone de la société</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>

            <div class="col-sm-4">
              <ValidationProvider rules="numeric|exact:10" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    v-model="editableItem.phone2"
                    type="number"
                    class="form-control"
                    id="phone2"
                    name="phone2"
                    placeholder="Téléphone de la société"
                  />
                  <label for="phone2">Téléphone alternatif société</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>

            <div class="col-sm-12">
              <h5>Effectifs de permanent</h5>
              <div class="row">
                <div class="col-sm-4">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        v-model="editableItem.effectifPermanentHomme"
                        type="number"
                        class="form-control"
                        id="effectifPermanentHomme"
                        name="effectifPermanentHomme"
                        placeholder="Effectif permanent Homme"
                      />
                      <label for="effectifPermanentHomme">Effectif permanent Homme</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-4">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        v-model="editableItem.effectifPermanentFemme"
                        type="number"
                        class="form-control"
                        id="effectifPermanentFemme"
                        name="effectifPermanentFemme"
                        placeholder="Effectif permanent Femme"
                      />
                      <label for="effectifPermanentFemme">Effectif permanent Femme</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-4">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        disabled
                        :value="
                          parseInt(editableItem.effectifPermanentFemme ?? 0) +
                          parseInt(editableItem.effectifPermanentHomme ?? 0)
                        "
                        type="number"
                        class="form-control"
                        id="effectifPermanent"
                        name="effectifPermanent"
                        placeholder="Effectif permanent Total"
                      />
                      <label for="effectifPermanent">Effectif permanent Total</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="col-sm-12">
              <h5>Effectifs occasionnel</h5>
              <div class="row">
                <div class="col-sm-4">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        v-model="editableItem.effectifTemporaireHomme"
                        type="number"
                        class="form-control"
                        id="effectifTemporaireHomme"
                        name="effectifTemporaireHomme"
                        placeholder="Effectif occasionnel Homme"
                      />
                      <label for="effectifTemporaireHomme"
                        >Effectif occasionnel Homme</label
                      >
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-4">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        v-model="editableItem.effectifTemporaireFemme"
                        type="number"
                        class="form-control"
                        id="effectifTemporaireFemme"
                        name="effectifTemporaireFemme"
                        placeholder="Effectif occasionnel Femme"
                      />
                      <label for="effectifTemporaireFemme"
                        >Effectif occasionnel Femme</label
                      >
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-4">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        disabled
                        :value="
                          parseInt(editableItem.effectifTemporaireFemme ?? 0) +
                          parseInt(editableItem.effectifTemporaireHomme ?? 0)
                        "
                        type="number"
                        class="form-control"
                        id="effectifTemporaire"
                        name="effectifTemporaire"
                        placeholder="Effectif occasionnel Total"
                      />
                      <label for="effectifTemporaire">Effectif occasionnel Total</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="col-sm-12">
              <h5>Effectifs Autres (stagiaires, bénévoles)</h5>
              <div class="row">
                <div class="col-sm-4">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        v-model="editableItem.effectifStagiaireHomme"
                        type="number"
                        class="form-control"
                        id="effectifStagiaireHomme"
                        name="effectifStagiaireHomme"
                        placeholder="Effectif statigiaire Homme"
                      />
                      <label for="effectifStagiaireHomme">Effectif stagiaire Homme</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-4">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        v-model="editableItem.effectifStagiaireFemme"
                        type="number"
                        class="form-control"
                        id="effectifStagiaireFemme"
                        name="effectifStagiaireFemme"
                        placeholder="Effectif stagiaire Femme"
                      />
                      <label for="effectifStagiaireFemme">Effectif stagiaire Femme</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-4">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        disabled
                        :value="
                          parseInt(editableItem.effectifStagiaireFemme ?? 0) +
                          parseInt(editableItem.effectifStagiaireHomme ?? 0)
                        "
                        type="number"
                        class="form-control"
                        id="effectifStagiaire"
                        name="effectifStagiaire"
                        placeholder="Effectif stagiaire Total"
                      />
                      <label for="effectifStagiaire">Effectif stagiaire Total</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="col-sm-12">
              <h5>Effectif total du personnel</h5>
              <div class="row">
                <div class="col-sm-4">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        disabled
                        :value="effectifPersonnelHomme"
                        type="number"
                        class="form-control"
                        id="effectifPersonnelHomme"
                        name="effectifPersonnelHomme"
                        placeholder="Effectif personnel Homme"
                      />
                      <label for="effectifPersonnelHomme">Effectif personnel Homme</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-4">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        disabled
                        :value="effectifPersonnelFemme"
                        type="number"
                        class="form-control"
                        id="effectifPersonnelFemme"
                        name="effectifPersonnelFemme"
                        placeholder="Effectif personnel Femme"
                      />
                      <label for="effectifPersonnelFemme">Effectif personnel Femme</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-4">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        disabled
                        :value="effectifPersonnel"
                        type="number"
                        class="form-control"
                        id="effectifPersonnel"
                        name="effectifPersonnel"
                        placeholder="Effectif personnel Total"
                      />
                      <label for="effectifPersonnel">Effectif personnel Total</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    v-model="editableItem.capaciteTotalAccueil"
                    type="number"
                    class="form-control"
                    id="capaciteTotalAccueil"
                    name="capaciteTotalAccueil"
                    placeholder="Capacités totales d’accueil sur les sites de stage"
                  />
                  <label for="capaciteTotalAccueil"
                    >Capacités totales d’accueil sur les sites de stage</label
                  >
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6">
              <ValidationProvider v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <div class="field col-12 md:col-12">
                    <label for="dateDebut">Date debut</label>
                    <!-- <p-calendar
                      id="dateDebut"
                      class="col-12 md:col-12"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      v-model="editableItem.dateDebut"
                      name="dateDebut"
                      dateFormat="dd/mm/yy"
                    ></p-calendar> -->
                    <DatePicker
                      class="flex-grow"
                      :first-day-of-week="2"
                      :masks="{ title: 'DD-MM-YYYY' }"
                      locale="fr"
                      v-model="editableItem.dateDebut"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <div class="form-floating mb-3 flex w-full">
                          <input
                            class="form-control"
                            :value="inputValue"
                            required
                            type="text"
                            v-on="inputEvents"
                          />
                          <label for="titre">Date de début</label>
                        </div>
                      </template>
                    </DatePicker>
                  </div>

                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6">
              <ValidationProvider v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <div class="field col-12 md:col-12">
                    <label for="dateFin">Date Fin</label>
                    <DatePicker
                      class="flex-grow"
                      :first-day-of-week="2"
                      :masks="{ title: 'DD-MM-YYYY' }"
                      locale="fr"
                      v-model="editableItem.dateFin"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <div class="form-floating mb-3 flex w-full">
                          <input
                            class="form-control"
                            :value="inputValue"
                            required
                            type="text"
                            v-on="inputEvents"
                          />
                          <label for="dateFin">Date de fin</label>
                        </div>
                      </template>
                    </DatePicker>
                  </div>

                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>

            <div class="col-sm-12">
              <!-- <label>Description de l'activité principale de l'entreprise</label> -->
              <label>Principales activités et processus de production</label>
              <ValidationProvider v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <div class="field col-12 md:col-12">
                    <!-- <PTextarea
                      class="col-12"
                      id="descriptionActiviteEntreprise"
                      v-model="editableItem.descriptionActiviteEntreprise"
                      rows="10"
                    /> -->
                    <PEditor
                      v-model="editableItem.descriptionActiviteEntreprise"
                      editorStyle="height: 320px"
                    />
                  </div>

                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>

      <ValidationObserver
        v-if="activeStep.name == 'DEROULEMENT_MISSION'"
        ref="observer1"
        v-slot="{ handleSubmit }"
      >
        <form ref="form1" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    v-model="editableItem.lieuReunionDemarrage"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="lieu"
                    name="lieu"
                    placeholder="lieu"
                  />
                  <label for="lieu">Lieu de réunion de démarrage</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-12">
              <ValidationProvider v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <div class="field col-12 md:col-12">
                    <label for="dateDebut">Date de la réunion de démarrage</label>
                    <!-- <p-calendar
                      id="dateDebut"
                      class="col-12 md:col-12"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      v-model="editableItem.dateReunionDemarrage"
                      name="dateDebut"
                      dateFormat="dd/mm/yy"
                    ></p-calendar> -->
                    <DatePicker
                      class="flex-grow"
                      :first-day-of-week="2"
                      :masks="{ title: 'DD-MM-YYYY' }"
                      locale="fr"
                      v-model="editableItem.dateReunionDemarrage"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <div class="form-floating mb-3 flex w-full">
                          <input
                            class="form-control"
                            :value="inputValue"
                            required
                            type="text"
                            v-on="inputEvents"
                          />
                          <label for="dateReunionDemarrage"
                            >Date de la réunion de démarrage</label
                          >
                        </div>
                      </template>
                    </DatePicker>
                  </div>

                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-12">
              <label>Description de l'itinéraire de la séance</label>
              <ValidationProvider v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <div class="field col-12 md:col-12">
                    <PTextarea
                      class="col-12"
                      id="itineraireReunion"
                      v-model="editableItem.itineraireReunion"
                      rows="10"
                    />
                  </div>

                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>

      <ValidationObserver
        v-if="activeStep.name == 'CONCLUSION_PARTICIPANTS'"
        ref="observer2"
        v-slot="{ handleSubmit }"
      >
        <form ref="form2" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <div class="col-sm-12">
              <label>Conclusion du rapport</label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <PEditor v-model="editableItem.rapport" editorStyle="height: 320px" />
                  <!-- <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span> -->
                </div>
              </ValidationProvider>
            </div>

            <div class="col-sm-12">
              <label>Participants</label>
              <ValidationProvider v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <div class="field col-12 md:col-12">
                    <PTextarea
                      class="col-12"
                      id="participants"
                      v-model="editableItem.participants"
                      rows="3"
                    />
                  </div>

                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>

            <div class="col-sm-12 field">
              <div class="form-floating mb-3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <label for="libelle"
                    >Liste des participants signée (fichier à joindre)</label
                  >
                  <div>
                    <FilePondUploader
                      ref="fileUploader"
                      class="col-12 md:col-12"
                      v-model="editableItem.participantsListUrl"
                      @save="fileSaved"
                      :autoResize="true"
                      rows="8"
                    />
                  </div>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-sm-12 field mb-5">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div>Auteur(s) du rapport(s)</div>
                <AsyncSearchInput
                  v-model="editableItem.auditeurs"
                  :multiple="true"
                  :queryUrl="'auditeurs'"
                  :optionLabel="'nomPrenom'"
                  placeholder="Rechercher un agent audit"
                />
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
    <template #footer>
      <PButton
        label="Quitter"
        icon="pi pi-times"
        @click="cancel"
        class="p-button-text p-button-danger"
      />
      <PButton
        label="Précédent"
        icon="pi pi-chevron-left"
        :disabled="activeStepIndex <= 0"
        class="p-button-outlined"
        @click.prevent="goToPrevious"
      />
      <PButton
        label="Suivant"
        icon="pi  pi-chevron-right"
        v-if="activeStepIndex < steps.length - 1"
        @click.prevent="goToNext"
      />
      <PButton
        label="Valider"
        icon="pi pi-check"
        @click="save"
        autofocus
        v-if="activeStepIndex >= steps.length - 1"
      />
    </template>
  </PDialog>
</template>

<script>
import DStepper from "../../../../../common/DStepper.vue";
import FilePondUploader from "../../../../../../components/uploader/FilePondUploader.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import AsyncSearchInput from "@/components/common/AsyncSearchInput.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      require: true,
    },
  },
  mixins: [],
  components: {
    DStepper,
    FilePondUploader,
    AsyncSearchInput,
    DatePicker,
  },
  data() {
    return {
      activeStepIndex: 0,
      displayDialog: false,
      editableItem: {},
    };
  },
  watch: {
    item() {
      this.editableItem = { ...this.item };
    },
    "editableItem.dateDebut": {
      handler(val) {
        this.editableItem.dateFin = val;
        this.editableItem.dateReunionDemarrage = val;
      },
      deep: true,
    },
  },
  computed: {
    effectifPersonnel() {
      return (
        parseInt(this.effectifPersonnelFemme ?? 0) +
        parseInt(this.effectifPersonnelHomme ?? 0)
      );
    },
    effectifPersonnelHomme() {
      return (
        parseInt(this.editableItem.effectifTemporaireHomme ?? 0) +
        parseInt(this.editableItem.effectifPermanentHomme ?? 0) +
        parseInt(this.editableItem.effectifStagiaireHomme ?? 0)
      );
    },
    effectifPersonnelFemme() {
      return (
        parseInt(this.editableItem.effectifTemporaireFemme ?? 0) +
        parseInt(this.editableItem.effectifPermanentFemme ?? 0) +
        parseInt(this.editableItem.effectifStagiaireFemme ?? 0)
      );
    },
    activeStep() {
      return this.steps[this.activeStepIndex];
    },
    steps() {
      let steps = [
        {
          name: "INFORMARTION_DE_BASE",
          label: "INFO DE BASE",
          subtitle: "Information de base",
          to: "#npiValidation",
          completed: this.activeStepIndex >= 0,
          command: () => {},
        },
      ];

      // if (this.editableItem.noteCriticite != null) {
      steps.push({
        name: "DEROULEMENT_MISSION",
        label: "DEROULEMENT MISSION",
        subtitle: "Déroulement de la mission",
        to: "#deroulementMission",
        completed: this.activeStepIndex >= 1,
        command: () => {},
      });

      steps.push({
        name: "CONCLUSION_PARTICIPANTS",
        label: "RAPPORTS ET PARTICIPANTS",
        subtitle: "Conclusion et participants",
        to: "#commentaire",
        completed: this.activeStepIndex >= 2,
        command: () => {},
      });
      // }
      return steps;
    },
  },
  methods: {
    async isCompleted(step) {
      const valid = await this.$refs["observer" + step].validate();
      return valid;
    },
    resetContent() {
      this.activeStepIndex = 0;
    },
    show() {
      // this.resetContent();
      this.displayDialog = true;
    },
    hide() {
      this.displayDialog = false;
    },
    async goToNext() {
      if (!(await this.isCompleted(this.activeStepIndex))) return;
      this.activeStepIndex += this.activeStepIndex < this.steps.length - 1 ? 1 : 0;
    },
    async goToPrevious() {
      this.activeStepIndex -= this.activeStepIndex > 0 ? 1 : 0;
    },
    fileSaved(files) {
      this.editableItem = {
        ...this.editableItem,
        participantsListUrl: this.$refs.fileUploader.getFilesUrls(),
      };
    },
    async save() {
      if (!(await this.isCompleted(this.steps.length - 1))) return;

      this.$emit("save", this.editableItem);
      this.hide();
    },
    close() {
      this.displayDialog = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
  },
};
</script>

<style>
.field-checkbox,
.field-radiobutton {
  display: flex;
  justify-items: center;
}
.stepper-dialog .p-dialog-header-icons {
  display: none;
}

.stepper-dialog .p-dialog-content {
  background: #faf4fa;
}

.stepper-dialog .p-dialog-footer {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
}

.stepper-dialog .p-dialog-header {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.5);
}

.shadow-light {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 3%) !important;
}

.msg-cloture.p-message .p-message-wrapper {
  padding: 0.5rem 1.2rem;
}
</style>
